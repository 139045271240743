import React from 'react'

export default function PrivacyPolicy() {
    return <div>
      <h1>Privacy Policy</h1>
      <p>
        Coming soon!
      </p>
    </div>
}
