import React from 'react';
import controlMusic from './images/control-music.jpg'
import getsPaid from './images/paid.jpg'

function Home() {
    return (
        <div>
        <section id="fm-banner" className="d-flex flex-wrap align-content-center">
          <div className="text-overlay text-white">
            <h1>Can I use your song?</h1>
            <p>FANDOMUSIC allows Artists to license their music directly to creators and fans in an easy, low friction way.</p>
          </div>
        </section>
        <section id="fm-services">
          <div className="container">
            <div className="controlmusic-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="text-wrap text-right">
                    <h2>Control your music</h2>
                    <p>Artists are in control of their music’s pricing and availability, and there’s no competition within the platform.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-wrap"> <img src={controlMusic} width="100%" alt=""/> </div>
                </div>
              </div>
            </div>
            <div className="paid-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="paid-img"> <img src={getsPaid} width="100%" alt=""/> </div>
                </div>
                <div className="col-md-6">
                  <div className="text-wrap">
                    <h2>Everyone gets paid</h2>
                    <p>An intuitive “splits” system allows the Artist to get paid directly for each individual license, and include any collaborators (co-writers, featured artists, managers, publishers, and more) on each and every payment.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="fm-create-account" className="d-flex flex-wrap align-content-center">
          <div className="text-overlay text-white">
            <h1>Yes you can !!!</h1>
            <p>In an age where everyone is a Content Creator, and fans want to use music they have a real connection to, this platform is the answer to the question: “Can I use your song in my video?”</p>
          </div>
          <div className="text-inner text-white text-center m-auto create-account">
            <h2>License your music directly and transparently- no middleman</h2>
            <button type="button" id="account-create-button" className="btn btn-primary">Create Account</button>
          </div>
        </section>
        <div className="line"></div>
        </div>
    )
}

export default Home;
