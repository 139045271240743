import React, { useState, useRef } from 'react';
import { Redirect } from 'react-router-dom'
// import * as store from "./store.js"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faTimes } from '@fortawesome/free-solid-svg-icons'
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay';
import store from "./store.js";

const cartRoutes = [
  "/artist"
]

const showCart = (location, hasCart) => {
  // @@ if we need to extend, will need a find
  if (location.pathname.startsWith(cartRoutes[0])) {
    return true
  }
  if (hasCart) {
    return true
  }
  return false
}

function Cart(props) {
  const placement = "bottom-end"
  const [state, actions] = store.useGlobal();
  const cart = state.cart
  const cartTarget = useRef(null);
  let runningTotal = 0;

  const [redirect, setRedirect] = useState(false)

  cart.forEach(cartItem => {
    return cartItem.price ? runningTotal = runningTotal + cartItem.price : null;
  });
  
    const overlay = (
      <>
        <span><Button ref={cartTarget} onClick={() => props.cartPopShower()} style={{ marginRight: `10px`, padding: `10px 16px`, outline: `none` }} className="btn-primary" size="sm"><FontAwesomeIcon icon={faShoppingCart} />{cart.length}</Button><div style={{ textAlign: `right`, fontSize: `9px`, margin: `-11px 1px 0px auto`, color: `#FF2800` }}>▼</div></span>
        <Overlay
          target={cartTarget.current}
          placement="bottom-end"
          show={props.cartPopShow}
          onHide={() => props.setCartPopShow(false)}
          rootClose={true}
          >
          {props => (<Popover {...props} style={{ padding: `18px`, marginRight: `-6px`, ...props.style }} id={`popover-positioned-${placement}`}>
            <Popover.Title style={{ margin: `-18px -18px 0 -18px` }} as="h3"> ${runningTotal.toFixed(2)} </Popover.Title>
            <div style={{ paddingTop: `12px`, paddingBottom: `12px` }}>
              {cart.map(purchase =>
                <Popover.Content style={{ display: `grid`, gridTemplateColumns: `2.5fr 1fr 1fr`, borderBottom: `1px solid #ddd` }}>
                  <span><strong>{purchase.title}</strong></span>
                  <span>${purchase.price ? purchase.price.toFixed(2): "0.00"}</span>
                  <span style={{ justifySelf: `end` }}><FontAwesomeIcon size="lg" color="#aaa" style={{ height: `16px`, width: `16px`, padding: `2px`, border: `1px solid #aaa`, borderRadius: `100%`, cursor: `pointer` }} icon={faTimes} onClick={() => actions.cart.deleteItem(purchase.path)} /></span>
                </Popover.Content>
              )}
              <Button style={{ marginTop: `22px`, marginBottom: `-4px` }} className="btn-primary" size="sm" block onClick={() => setRedirect(true)}>
                Checkout
              </Button>
            </div>
          </Popover>)}
        </Overlay>
      </>
    )

    if (redirect) {
      return <Redirect push to="/checkout" />
    } else {
      return overlay
    }
  }

export {
    Cart,
    showCart
  }
