import React, { useState, useEffect } from 'react';
import firebaseApp, { firestore as db } from './firebase.js'
import styles from './ProjectStore.module.css'
//import playIcon from './images/play.png'
// @@ replace with FA
// import playtime from './images/playing-time.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faFacebook, faTwitter, faInstagram, faSpotify } from "@fortawesome/free-brands-svg-icons"
import { faCartPlus, faPlayCircle, faPauseCircle } from '@fortawesome/free-solid-svg-icons'
import Card from 'react-bootstrap/Card'
import placeholder from './images/album-art-placeholder.png'
import ReactAudioPlayer from 'react-audio-player'
import Image from 'react-bootstrap/Image'
import store from "./store.js";

import SpinnerOverlay from './SpinnerOverlay'

var storage = firebaseApp.storage()

function WorkRow({ work, index, playing, setPlaying }) {
  const [state, actions] = store.useGlobal();
  const [songfileUrl, setSongfileUrl] = useState()
  const [rap, setRap] = useState()

  var disabled = false
  if (state.cart.map(work => work.path).includes(work.path)) {
    disabled = true
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const songfileRef = storage.ref([work.releasePath, 'workFiles', work.slug, work.songFile].join('/'))
        const url = await songfileRef.getDownloadURL()
        setSongfileUrl(url)
      } catch (err) {
        console.error("SongFile not found for " + work.slug)
        console.error(err)
      }

    }

    if (work.songFile) {
      fetchData()
    }
  }, [work.path, work.songFile, work.releasePath, work.slug])

  let playIcon = faPlayCircle

  if (rap && !playing.active) {
    rap.audioEl.pause()
    playIcon = faPlayCircle
  }

  if (playing) {
    if (playing.active && (playing.key[0] !== index[0]) && (playing.key[1] !== index[1])) {
      rap.audioEl.pause()
      playIcon = faPlayCircle
    }

    if (playing.active && (playing.key[0] === index[0]) && (playing.key[1] === index[1])) {
      rap.audioEl.play()
      playIcon = faPauseCircle
    }
  }

  const handlePlayClick = () => {
    if ((playing.active && playing.key[0] !== index[0] && playing.key[1] !== index[1]) || !playing.active) {
      setPlaying({ active: true, key: index })
    } else {
      setPlaying({ active: false })
    }
  }

  return (
    <span className={styles['work-row']} >
      <div className={styles['td-play-button']} onClick={handlePlayClick} >
        <ReactAudioPlayer src={songfileUrl} ref={element => setRap(element)} />
        <FontAwesomeIcon className={styles['play-button']} icon={playIcon} />
      </div>
      <div className={styles['td-work-title']} onClick={handlePlayClick} >{work.title}</div>

      <div className={styles['td-cart-button']}>
        {disabled ? <FontAwesomeIcon style={{ color: 'grey' }} icon={faCartPlus} /> : <FontAwesomeIcon className={styles['cart-icon']} style={{ color: 'black' }} icon={faCartPlus} onClick={
          (event) => actions.cart.addItem(work)
        } />}
      </div>
      <div className={styles['td-work-price']}>${work.price ? work.price.toFixed(2) : "Free"}</div>
    </span>
  )
}

function ProjectStore({ match }) {
  const projectSlug = match.params.projectSlug;
  const [loading, setLoading] = useState(true)
  const [project, setProject] = useState()
  const [bannerImage, setBannerImage] = useState('')
  const [projectImage, setProjectImage] = useState('')
  const [playing, setPlaying] = useState({ active: false })

  useEffect(() => {
    async function fetchData() {
      // get project
      const projectRef = db.collection('projects').doc(projectSlug)
      const releasesRef = projectRef.collection('releases')
      let doc = await projectRef.get()
      let project
      if (doc.exists) {
        project = doc.data()
        let snapshot = await releasesRef.get()
        let releases = snapshot.docs.map(async doc => {
          var releaseRef = doc.ref
          var release = doc.data()
          var releasePath = doc.ref.path

          async function getReleaseImage() {
            try {
              const releaseImageRef = storage.ref(['projects', projectSlug, 'releases', release.slug, 'artwork', 'coverPhoto.jpg'].join('/'))
              let releaseImage = await releaseImageRef.getDownloadURL()
              release.image = releaseImage
            }
            catch (err) {
              console.log(err)
            }

          }
          await getReleaseImage()

          var worksRef = releaseRef.collection('works').orderBy('trackNo')
          let snapshot = await worksRef.get()
          var works = snapshot.docs.map(doc => {
            var work = doc.data()

            work = {
              path: doc.ref.path,
              releasePath: releasePath,
              title: work.title,
              slug: work.slug,
              price: work.price,
              songFile: work.songFile
            }

            return work
          })
          release.works = works
          return release
        })
        project.releases = await Promise.all(releases)
        setProject(project)
        document.title = (project.name + " | FANDO MUSIC")
        setLoading(false)
      } else {
        setLoading(false)
        return
      }

      // if project exists, set the bg image
      const bannerImageRef = storage.ref(['projects', projectSlug, 'banner_image.jpg'].join('/'))
      let url = await bannerImageRef.getDownloadURL()
      setBannerImage(url)
      const projectImageRef = storage.ref(['projects', projectSlug, 'project_image.jpg'].join('/'))
      url = await projectImageRef.getDownloadURL()
      setProjectImage(url)

    }

    fetchData()
  }, [projectSlug])

  if (loading) {
    return <SpinnerOverlay />
  }

  if (!project) {
    return <Card className={styles['project-not-found']}><h3>Project not found</h3></Card>
  }

  return (<>
    <img alt={placeholder} className={styles['background-image']} src={bannerImage ? bannerImage : placeholder} />
    <div className={styles['store-container']}>

      <div className={styles['releases-container']}>
        <div className={styles['releases-header-text']}>
          <h5>Songs available to license</h5> </div>
        {project.releases && project.releases.map((release, index) => {
          let releaseIndex = index
          return <>
            <div className={styles['release-name']}>
              <img alt={placeholder} className={styles['release-art']} src={release.image ? release.image : placeholder} />
              <div className={styles['release-name-text']}><strong>{release.name}</strong></div>
            </div>
            <div className={styles['release-table']}>

              {release.works.map((work, index) => <WorkRow key={[releaseIndex, index]} index={[releaseIndex, index]} work={work} playing={playing} setPlaying={setPlaying} />)}

            </div>
          </>
        }
        )
        }
      </div>

      <div className={styles['project-profile']}>
        <div className={styles['project-profile-header']}><h1 className={styles['project-profile-name']}>{project.name}</h1>
          <p className={styles['project-profile-description']}>{project.description}</p>
        </div>
        {/* <span>Talk to me</span>
            <ul className="social pl-0 mt-2">
            <li className="d-inline-block p-2"><FontAwesomeIcon icon={faFacebook} /></li>
            <li className="d-inline-block p-2"><FontAwesomeIcon icon={faTwitter} /></li>
            <li className="d-inline-block p-2"><FontAwesomeIcon icon={faInstagram} /></li>
            <li className="d-inline-block p-2"><FontAwesomeIcon icon={faSpotify} /></li> */}
        {/* <li class="d-inline-block p-2"><img src="images/envelop.png" width="24" height="17" alt=""/></li> */}
        {/* </ul> */}
        <Image className={styles['project-image-circle']} roundedCircle src={projectImage ? projectImage : placeholder} alt="" />
      </div>
    </div>
  </>
  );
}

export default ProjectStore
export {
  WorkRow
}
