import React from 'react'
import styles from './fando-general-stuff.module.css'

import Card from 'react-bootstrap/Card'

export default function ContactUs() {
  return <Card className={styles['container-card']}>
    <h1> Contact Us </h1>
    <br />
    e-mail us at <code>contact at fandomusic dot com</code>
  </Card>
}
