import React, { useState, useEffect } from 'react'
import { firestore as db } from './firebase.js'
import * as firebase from 'firebase'
import { clearanceState } from "./enums.js"

import styles from './Admin.module.css'

import Card from 'react-bootstrap/Card'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import { CSVLink } from 'react-csv'


function Admin() {
  const [readyLicenses, setReadyLicenses] = useState([])
  const [allChecked, setAllChecked] = useState(true)
  const [csvData, setCsvData] = useState([])
  const [showDownload, setShowDownload] = useState(false)
  const [dateTimeString, setDateTimeString] = useState()
  const [groups, setGroups] = useState([])
  const [pendingGroups, setPendingGroups] = useState([])
  const [clearedGroups, setClearedGroups] = useState([])
  const [allGroupsChecked, setAllGroupsChecked] = useState(true)

  useEffect(() => {
    async function fetchData() {
      // @@ use clearance enum
      let docRef = db.collection('licenses').where('clearance', '==', 0)
      docRef.get().then(function (querySnapshot) {
        const readyLicenses = []
        querySnapshot.forEach(doc => {
          readyLicenses.push({ checked: true, id: doc.id, data: doc.data() })
        })
        console.log(readyLicenses)
        setReadyLicenses(readyLicenses)
      }).catch(function (error) {
        console.log("Error getting documents", error);
      })

      docRef = db.collection('clearanceGroups')
      docRef.get().then(function (querySnapshot) {
        const newGroups = []
        querySnapshot.forEach(doc => {
          newGroups.push({ ...doc.data(), checked: true })
        })
        let newClearedGroups = newGroups.filter((group) => {
          return group.cleared
        })
        let newPendingGroups = newGroups.filter((group) => {
          return !group.cleared
        })
        console.log(newGroups)
        setGroups(newGroups)
        setClearedGroups(newClearedGroups)
        setPendingGroups(newPendingGroups)
      })

    }

    fetchData()
  }, [])

  function handleAllChecked() {
    setShowDownload(false)
    let newAllChecked = !allChecked
    let newLicenses = [...readyLicenses]
    if (allChecked) {
      newLicenses.forEach(license => {
        license.checked = false
      }
      )
    } else {
      newLicenses.forEach(license => {
        license.checked = true
      })
    }
    setAllChecked(newAllChecked)
    setReadyLicenses(newLicenses)

  }

  function handleCheckbox(index) {
    setShowDownload(false)
    let newLicenses = [...readyLicenses]

    newLicenses[index].checked = !newLicenses[index].checked
    if (!newLicenses[index].checked) {
      setAllChecked(false)
    }
    setReadyLicenses(newLicenses)
  }

  function generateCsv(e) {
    e.preventDefault();
    let checkedLicenses = readyLicenses.filter(function (license) {
      return license.checked
    })

    let checkedLicenseData = checkedLicenses.map((license) => {
      let flattened = license.data
      flattened = { ...flattened, id: license.id }
      return flattened
    })
    setShowDownload(true)

    let newCsvData = checkedLicenseData.map(license => {
      console.log(license.dateAssigned.toDate())
      return {projectName: license.projectName, releaseName: license.releaseName, workTitle: license.workTitle, url: license.url, date: license.dateAssigned.toDate()}
    })

    setCsvData(newCsvData)
    console.log(checkedLicenseData)
    let currentDate = new Date()
    let dateString = ([currentDate.getUTCFullYear(), ("0" + (currentDate.getUTCMonth() + 1)).slice(-2), ("0" + currentDate.getUTCDate()).slice(-2)].join('-'))
    let timeString = ([("0" + (currentDate.getUTCHours())).slice(-2), ("0" + (currentDate.getUTCMinutes())).slice(-2), ("0" + (currentDate.getUTCSeconds())).slice(-2)].join(':'))
    let dateTimeStr = dateString + " " + timeString
    console.log(dateTimeStr)

    setDateTimeString(dateTimeStr)

    let licenseRefs = checkedLicenseData.map(license => {
      db.collection('licenses').doc(license.id).update({ clearance: clearanceState.pending })
      return db.collection('licenses').doc(license.id)
    })

    db.collection('clearanceGroups').doc(dateTimeStr).set({ dateSubmitted: firebase.firestore.Timestamp.now(), licenses: licenseRefs, cleared: false, clearedDate: null })

  }


  function handleAllGroupsChecked() {
    let newAllGroupsChecked = !allGroupsChecked
    let newGroups = [...groups]
    if (allGroupsChecked) {
      newGroups.forEach(group => {
        group.checked = false
      })
    } else {
      newGroups.forEach(group => {
        group.checked = true
      })
    }
    setAllGroupsChecked(newAllGroupsChecked)
    setGroups(newGroups)
  }

  function handleGroupCheck(index) {
    let newGroups = [...groups]
    newGroups[index].checked = !newGroups[index].checked
    if (!newGroups[index].checked) {
      setAllGroupsChecked(false)
    }
    setGroups(newGroups)
  }

  function handleSubmitCleared(e) {
    e.preventDefault()
    let checkedGroups = groups.filter(group => {
      return group.checked
    })
    checkedGroups.forEach(group => {
      let docRef = db.collection('clearanceGroups').where('dateSubmitted', '==', group.dateSubmitted)
      docRef.get().then(function (querySnapshot) {
        console.log(querySnapshot)
        let docId =  querySnapshot.docs[0].id
        db.collection('clearanceGroups').doc(docId).update({cleared: true, dateCleared: firebase.firestore.Timestamp.now()})
      })
    })
    checkedGroups.forEach(group => {
      let clearLicenses = group.licenses
      clearLicenses.forEach(license => {
        db.collection('licenses').doc(license.id).update({clearance: clearanceState.cleared, dateCleared: firebase.firestore.Timestamp.now()})
      })
    })
  }

  const toClearTab = (<Tab eventKey="assigned" title="Assigned">
    <h3>Assigned Licenses</h3>
    <Form onSubmit={(e) => generateCsv(e)}>
      <Form.Group>
        <Form.Check type="checkbox" checked={allChecked} onChange={() => handleAllChecked()} label="Select All Licenses" />
      </Form.Group>
      <Table hover>
        <thead>
          <th>Select</th>
          <th>Assigned URL</th>
          <th>Work Title</th>
          <th>Project Name</th>
          <th>Release Name</th>
          <th>Purchase Date</th>
        </thead>
        <tbody>
          {readyLicenses.length > 0 ? readyLicenses.map((license, index) => {
            return (<tr>
              <td><Form.Check type="checkbox" checked={readyLicenses[index].checked} onChange={() => handleCheckbox(index)} label="" /></td>
              <td><a href={license.data.url}>{license.data.url}</a></td>
              <td>{license.data.workTitle}</td>
              <td>{license.data.projectName}</td>
              <td>{license.data.releaseName}</td>
              <td>{license.data.datePurchased.toDate().getMonth() + 1}/{(license.data.datePurchased.toDate().getDate())}/{license.data.datePurchased.toDate().getFullYear()}</td></tr>)
          }) : "There are no licenses currently waiting for clearance."}
        </tbody>

      </Table>
      <Button variant="outline-dark" type="submit">Generate CSV</Button>
      {showDownload ? <CSVLink filename={'fando-clearance-' + dateTimeString + '.csv'} data={csvData}>Download CSV</CSVLink> : null}
    </Form>


  </Tab>)

  const clearanceGroupsTab = (<Tab eventKey="clearanceGroups" title="Pending Clearance Groups">
    <h3>Pending Clearance Groups</h3>
    <Form onSubmit={(e) => handleSubmitCleared(e)}>
      <Form.Group>
        <Form.Check type="checkbox" checked={allGroupsChecked} onChange={() => handleAllGroupsChecked()} label="Select All Groups" />
      </Form.Group>
      <Table hover>
        <thead>
          <th>Select</th>
          <th>Group Submitted Date</th>
          <th>Licenses</th>
        </thead>
        <tbody>
          {pendingGroups.length > 0 ? pendingGroups.map((group, index) => {
            return (<tr>
              <td><Form.Check type="checkbox" checked={groups[index].checked} disabled={groups[index].cleared} onChange={() => handleGroupCheck(index)} /></td>
              <td>{group.dateSubmitted.toDate().toString()}</td>
              <td>{group.licenses.map((license) => {
                  console.log(license)
                  return (license.path + " | ")
              })}</td>
            </tr>)
          }) : "There are no clearance groups pending."}
        </tbody>
      </Table>
      <Button variant="outline-dark" type="submit">Mark as cleared</Button>
    </Form>
  </Tab>)

  const clearedGroupsTab = (<Tab eventKey="clearedThings" title="Cleared Groups">
    <h3>Cleared Groups</h3>
    <Table hover>
      <thead>
        <th>Date Cleared</th>
        <th>Licenses</th>
      </thead>
      <tbody>
        {clearedGroups.length > 0 ? clearedGroups.map((group) => {
          return (<tr>
            <td>{group.dateCleared.toDate().toString()}</td>
            <td>{group.licenses.map(license => {
                return (license.path + " | ")
            })}</td>
          </tr>)
        }) : "There are no groups that have been cleared."}
      </tbody>
    </Table>
  </Tab>)

  return (
    <Card className={styles['admin-container']}>
      <div className={styles['container-title']}><h2>License Admin</h2></div>
      <div className={styles['admin-body']}>
        <Tabs defaultActiveKey="assigned">
          {toClearTab}
          {clearanceGroupsTab}
          {clearedGroupsTab}
        </Tabs>
      </div>
    </Card>
  )
}

export default Admin
