const clearanceState = {
  cleared: 2,
  pending: 1,
  assigned: 0,
  unassigned: null
}

export {
  clearanceState,
}
