import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { devSettings } from './firebase.js'




function DevAdmin(){
  let [emuToggleIndicator, setEmuToggleIndicator] = useState(devSettings.emulators);

  function toggleEmulator() {
    devSettings.emulators = !devSettings.emulators;
    localStorage.setItem("dev-settings", JSON.stringify(devSettings))
    setEmuToggleIndicator(!emuToggleIndicator)
    window.location.reload();
  }



  return (
    <div style={{position: `fixed`, bottom: `0px`, right: `0px`, width: `300px`, height: `40px`, padding: `4px`, backgroundColor: `red`}}>
      <span style={{color: `white`, fontWeight: `bold`}}>Dev Settings: </span>
      <Button size="sm" style={{display: `inline-block`, height: `30px`, width: `90px`, fontSize: `14px`, padding: `2px 4px`}} variant={devSettings.emulators ? "warning" : "dark"} onClick={() => toggleEmulator()}>
        {devSettings.emulators ? "Emu" : "Non-Emu"}
      </Button>

    </div>
  )
}

export default DevAdmin;
