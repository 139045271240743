import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/firestore'
import config from './config.json'

const firebaseApp = firebase.initializeApp(config);
const functions = firebaseApp.functions()

var hostname = window.location.hostname

const devSettings = JSON.parse(localStorage.getItem('dev-settings')) || { emulators: false }
console.log(devSettings)

var db = firebaseApp.firestore();

if (hostname === "localhost" && devSettings.emulators === true) {
  db.settings({
    host: "localhost:8080",
    ssl: false
  });
  functions.useFunctionsEmulator("http://localhost:5001")
}

function setDb(database) {
  db = database
}

export {
  firebaseApp as default,
  db as firestore,
  setDb,
  devSettings,
}
