import React, { useState, useEffect } from 'react'
import { Formik, FieldArray, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import { diff } from 'deep-object-diff'

import styles from '../Dashboard.module.css'

import urlSlug from 'url-slug'
import fieldArrayValidator from '../fieldArrayValidator'

import FileDrop from 'react-file-drop'
import * as mmb from 'music-metadata-browser';

import SplitModal from './SplitModal'
import SplitInput from '../SplitInput'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompactDisc, faMusic, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'

import firebaseApp, { firestore as db } from '../../firebase'

import placeholder from '../../images/album-art-placeholder.png'


function FileDropper({ values, setFieldValue, formikFileSetter }) {
  const [allFiles, setAllFiles] = useState([])

  const handleDrop = async (files, event) => {
    event.preventDefault()
    console.log(files, event);
    let fileArray = [...files]
    let newFiles = fileArray.map(async (file) => {
      try {
        let extractedTitle = await mmb.parseBlob(file).then(function (meta) { return meta.common.title })

        let title = extractedTitle

        if (!extractedTitle) {
          title = file.name
        }

        return { file: file, title: title, slug: urlSlug(title) }
      } catch (error) {
        console.error(error)
        //@@ Revisit logic
        return { file: null, title: 'Not an audio file', slug: urlSlug("invalid") }
      }

    })

    let droppedFiles = allFiles.concat(await Promise.all(newFiles))
    setAllFiles(droppedFiles)

    formikFileSetter({ droppedFiles, values, setFieldValue })

  }
  return (
    <FileDrop className="file-drop" onDrop={handleDrop}>
      Drag & Drop WAV or MP3 here
    </FileDrop>
  )

}

export default function ReleaseModal(props) {

  let initialRelease = props.release
  let initialProject = props.project




  const [newReleaseImage, setNewReleaseImage] = useState()
  const [useCustomSplit, setUseCustomSplit] = useState(false)
  const [publish, setPublish] = useState(false)
  const [newFiles, setNewFiles] = useState([])
  const [splitModalShow, setSplitModalShow] = useState(false)

  let storage = firebaseApp.storage()

  useEffect(() => {
    if (initialRelease && initialRelease.split && (Object.keys(diff(initialProject.splitRef, initialRelease.splitRef)).length > 0)) {
      console.log(initialRelease)
      setUseCustomSplit(true)
    }
    return () => {
      setUseCustomSplit(false)
      setNewReleaseImage()
    }
  }, [initialProject, initialRelease, props.show])



  let initialValues
  let editMode = false

  if (initialRelease) {
    editMode = true
    if (initialRelease.split) {
      // let newWorks = initialRelease.works.map(work => {

      //   if (!work.split) {
      //     work.split = initialRelease.split
      //     work.splitRef = initialRelease.splitRef
      //   }
      //   return work
      // })
      initialValues = {
        name: initialRelease.name,
        price: initialRelease.price,
        split: initialRelease.split,
        works: initialRelease.works,
        splitRef: initialRelease.splitRef
      }

    } else {
      let newWorks = initialRelease.works.map(work => {
        if (!work.split) {
          work.split = initialProject.split ? initialProject.split : [{ name: '', paypalEmail: '', percent: '100' }]
          // work.splitRef = initialProject.splitRef ? initialProject.splitRef : null
        }
        return work
      })

      initialValues = {
        name: initialRelease.name,
        price: initialRelease.price,
        split: [{ name: '', paypalEmail: '', percent: '100', fandoUid: null }],
        works: newWorks
      }
    }

  } else {
    initialValues = {
      name: '',
      price: initialProject && initialProject.price ? initialProject.price : '',
      slug: '',
      works: [],
      split: [{ name: '', paypalEmail: '', percent: '100', fandoUid: null }]
    }

  }


  function formikFileSetter({ droppedFiles, values, setFieldValue }) {


    droppedFiles = droppedFiles.map((file) => {
      file.price = (values.price ? values.price : null)
      file.split = (initialRelease && initialRelease.split ? initialRelease.split : initialProject.split ? initialProject.split : [{ name: '', paypalEmail: '', percent: '100' }])
      return file
    })


    setFieldValue('works', droppedFiles)
    setNewFiles(droppedFiles)
  }

  const ReleaseSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too short!')
      .max(50, 'Too Long!')
      .required('You must choose a Release Name'),
    price: Yup.number()
      .positive('Price cannot be negative')
      .required('Required'),
    works: Yup.array().of(Yup.object().shape({
      title: Yup.string()
        .min(2, 'Too Short!')
        .max(100, 'Too Long!')
        .required('Must contain at least 1 track, and tracks must be titled.'),
      price: Yup.number()
        .positive()
        .required(),
      split: Yup.array().of(Yup.object().shape({
        name: Yup.string()
          .required(''),
        paypalEmail: Yup.string()
          .email('Invalid')
          .required(''),
        percent: Yup.number()
          .positive()
          .min(1, 'Must be a number between 1 and 100')
          .required('')
      }))
    }))

  })






  return <>
    <div className={splitModalShow ? styles['overlay'] : null}>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className={styles['modal-general']}>
          <Modal.Header className={styles['modal-header']} closeButton>
            <div className={styles['modal-title-left']}>
              <FontAwesomeIcon size="lg" className={styles['album-icon']} icon={faCompactDisc} />
              <h4>{editMode ? "EDIT RELEASE" : "ADD A RELEASE"}</h4>
            </div>
            <div className={styles['modal-title-right']} >
              <FontAwesomeIcon size="lg" className={styles['music-icon']} icon={faMusic} />
              <h4>ADD TRACKS</h4>
            </div>

          </Modal.Header>
          <div className={styles['project-name']}><h5>{initialProject ? initialProject.name : null}</h5></div>
          <Formik
            initialValues={initialValues}
            validationSchema={ReleaseSchema}
            enableReinitialize={true}
            onSubmit={async values => {
              props.setLoading(true)

              let releaseSlug = initialRelease && initialRelease.slug ? initialRelease.slug : urlSlug(values.name)
              let releaseRef = db.collection('projects').doc(initialProject.slug).collection('releases').doc(releaseSlug)

              //SET RELEASE
              async function pushRelease() {

                releaseRef.set({
                  name: values.name,
                  slug: releaseSlug,
                  price: values.price,
                  published: publish
                },
                  { merge: true })
                console.log("Release Set")
                return
              }

              //SET WORKS
              async function pushWorks() {
                values.works.map((work, index) => {
                  //SANITIZE WORK FILES RECORDS FOR DATABASE
                  let workChanged = ((Object.keys(diff(initialValues.works[index], work)).length > 0) || !editMode)
                  if (workChanged) {
                    if (!work.slug) {
                      work.slug = urlSlug(work.title)
                    }
                    if (!work.file && !work.songFile) {
                      work.songFile = "none"
                    } else if (!work.songFile) {
                      work.songFile = work.file.name
                    }

                    //@@ TRACK NUMBER IS PLACEHOLDER FOR NOW. Will implement drag-n-drop rearangement
                    let trackNo = String(index + 1).padStart(2, '0')
                    work.trackNo = trackNo

                    const workRef = releaseRef.collection('works').doc(work.slug)

                    const workSetter = {
                      slug: work.slug,
                      price: work.price,
                      songFile: work.songFile,
                      title: work.title,
                      trackNo: work.trackNo,
                    }
                    workRef.set(workSetter)

                  }
                  return work
                })
                return
              }

              //SET SPLITS/PAYEES
              async function pushReleaseSplits() {
                let sameAsProject = (initialProject && initialProject.split && !useCustomSplit)
                let releaseSplitChanged = (((!editMode && !sameAsProject) || (!sameAsProject && Object.keys(diff(initialValues.split, values.split)).length > 0)))
                console.log(releaseSplitChanged)

                if (sameAsProject) {
                  db.collection('projects').doc(initialProject.slug).collection('releases').doc(releaseSlug).set({ splitRef: initialProject.splitRef }, { merge: true })
                } else if (releaseSplitChanged || useCustomSplit) {

                  let splitPayees = []
                  try {
                    await Promise.all(values.split.map(async payee => {

                      let payeeRef = db.collection('payees').doc(payee.paypalEmail)
                      payeeRef.set({ name: payee.name, paypalEmail: payee.paypalEmail }, { merge: true })
                      splitPayees.push({ percent: payee.percent, payee: payeeRef }) //CHANGE PROPERTY TO payeeRef ONCE BACK-END IS IN AGREEMENT
                    }))
                  } catch (error) {
                    console.error("There was an error when setting payees: " + error)
                  }

                  try {
                    let splitPayeesObject = Object.assign({}, splitPayees)
                    const splitRef = db.collection('projects').doc(initialProject.slug).collection('splits').doc()

                    await splitRef.set({ type: "release", payees: splitPayeesObject })
                    db.collection('projects').doc(initialProject.slug).collection('releases').doc(releaseSlug).set({ splitRef: splitRef }, { merge: true })
                    console.log("Release Payees successfully written!")
                    console.log("Release splitRef successfully written!")
                  } catch (error) {
                    console.error("There was an error when setting split payees: " + error)
                  }
                }
                return
              }


              async function pushWorkSplits() {
                values.works.forEach(async (work, index) => {

                  //FLAGS
                  let updated = (!editMode || ((initialRelease.works[index] && values.works[index] && (Object.keys(diff(initialValues.works[index].split, work.split)).length > 0))))
                  let sameAsRelease = ((useCustomSplit && !work.split) || (initialRelease?.split && work?.split && (Object.keys(diff(initialRelease?.split, work?.split)).length === 0)))
                  let sameAsProject = ((!useCustomSplit && !work.split) || (initialProject?.split && work?.split && (Object.keys(diff(initialProject.split, work.split)).length === 0)) || ( initialValues?.works?.index?.split && work?.split && initialProject && ((Object.keys(diff(initialValues.works[index].split, work.split)).length === 0) && (Object.keys(diff(initialProject.splitRef, initialValues.splitRef)).length === 0) )))
                  let newWork = (!initialRelease || (initialRelease && initialRelease.works[index] && (Object.keys(diff(initialRelease.works[index], values.works[index]).length > 0)) && !initialRelease.works[index].splitRef))
                  let customSplit = (initialProject?.split && work?.split && (Object.keys(diff(initialProject.split, work.split)).length > 0)) || (editMode && (initialRelease?.split && work?.split && Object.keys(diff(initialRelease.split, work.split)).length > 0))

                  if (!work.slug) {
                    work.slug = urlSlug(work.title)
                  }

                  if (updated || !work.split ) {
                    console.log("updated")

                    if (sameAsRelease) {
                      console.log("sameAsRelease")
                      const workRef = releaseRef.collection('works').doc(work.slug)
                      workRef.set({ splitRef: initialRelease.splitRef }, { merge: true })
                    } else if (sameAsProject) {
                      console.log("sameAsProject")
                      console.log(initialProject.splitRef)
                      const workRef = db.collection('projects').doc(initialProject.slug).collection('releases').doc(releaseSlug).collection('works').doc(work.slug)
                      workRef.set({ splitRef: initialProject.splitRef }, { merge: true })
                    } else if (customSplit || newWork) {
                      console.log("customSplit")
                      let splitPayees = []
                      try {
                        await Promise.all(work.split.map(async payee => {

                          let payeeRef = db.collection('payees').doc(payee.paypalEmail)
                          payeeRef.set({ name: payee.name, paypalEmail: payee.paypalEmail }, { merge: true })
                          splitPayees.push({ percent: payee.percent, payee: payeeRef }) //CHANGE PROPERTY TO payeeRef ONCE BACK-END IS IN AGREEMENT
                        }))
                        console.log("Custom Work Split Set")
                      } catch (error) {
                        console.error("There was an error when setting track payees: " + error)
                      }

                      try {
                        // let splitPayeesObject = Object.assign({}, splitPayees)
                        const splitRef = db.collection('projects').doc(initialProject.slug).collection('splits').doc()

                        await splitRef.set({ type: 'work', payees: splitPayees })
                        console.log("Track Payees for " + work.slug + " successfully written")
                        db.collection('projects').doc(initialProject.slug).collection('releases').doc(releaseSlug).collection('works').doc(work.slug).set({ splitRef: splitRef }, { merge: true })
                        console.log("Track splitRef for " + work.slug + " successfully written")
                      } catch (error) {
                        console.error("There was an error when setting track splits: " + error)
                      }

                    }
                  } else {
                    releaseRef.collection('works').doc(work.slug).update({splitRef: work.splitRef})
                  }
                })
                return
              }

              //UPLOAD ARTWORK
              async function artUpload() {
                if (newReleaseImage) {
                  try {
                    let coverPhotoRef = storage.ref(['projects', initialProject.slug, 'releases', releaseSlug, 'artwork', 'coverPhoto.jpg'].join('/'))
                    await coverPhotoRef.put(newReleaseImage)
                    console.log("Cover Art Uploaded")
                    if (newFiles.length === 0) {
                      props.setLoading(false)
                      props.onHide()
                      setNewReleaseImage()
                    }
                  } catch (error) {
                    console.error("Error on Cover Art Upload" + error)
                  }
                }
                return
              }

              //UPLOAD AUDIO FILES
              async function audioUpload() {
                if (newFiles.length > 0) {
                  await Promise.all(newFiles.map(async (file, index) => {
                    let workRef = db.collection('projects').doc(initialProject.slug).collection('releases').doc(releaseSlug).collection('works').doc(file.slug)
                    workRef.set({
                      title: file.title,
                      price: parseFloat(file.price),
                      songFile: file.file.name,
                      slug: file.slug,
                      trackNo: file.file.name.slice(0, 2)
                    })
                    try {
                      let workFilesRef = storage.ref(['projects', initialProject.slug, 'releases', releaseSlug, 'workFiles', file.slug, file.file.name].join('/'))
                      await workFilesRef.put(file.file)

                      console.log("File " + index + " Uploaded")

                      props.setLoading(false)
                      props.onHide()
                    } catch (error) {
                      console.error("Error with File upload" + error)
                    }

                  })
                  )
                }
                return
              }


              // if (!newReleaseImage && newFiles.length === 0) {
              //   props.setLoading(false)
              //   props.onHide()
              // } else {
              //   props.onHide()
              //   props.setLoading(false)
              // }

              props.setLoading(true)
              pushRelease()
              pushWorks()
              await audioUpload()
                .then(() => {
                  pushWorkSplits()
                }).then(async () => {
                  await artUpload()
                }).then(async () => {
                  await pushReleaseSplits()
                    .then(() => {
                      props.setLoading(false)
                      props.onHide()
                    })
                }).catch(error => {
                  alert("Something went wrong. Please try again!")
                  console.error("ONSUBMIT FAILED:")
                  console.error(error)
                })



            }
            }


            render={({
              values,
              setFieldValue,
              setValues,
              errors,
              touched,
              isValid,
              handleChange,
              handleBlur
            }) => {
              // console.log(errors)
              return (<FormikForm className={styles['form']} >
                <Modal.Body className={styles['modal-body']}>
                  <div className={styles['left-pane-release']}>
                    <Form.Label>
                      Release Title
                      {touched.name && errors.name ?
                        <span className={styles['form-error']}>{errors.name}</span> :
                        null}
                    </Form.Label>
                    <Form.Control
                      id="name"
                      name="name"
                      type="name"
                      value={values.name}
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && errors.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <div className={styles['release-create-art']} >
                      <Image src={newReleaseImage ?
                        URL.createObjectURL(newReleaseImage) :
                        initialRelease && initialRelease.image ?
                          initialRelease.image :
                          placeholder} />
                    </div>
                    <div className={styles['file-input-release-art']}>
                      <Form.Row>

                        <Form.File as={Col} id="releaseImage" custom>
                          <Form.File.Input
                            name="releaseImage"
                            onChange={(event) => { setNewReleaseImage(event.currentTarget.files[0]) }}
                          />
                          <Form.File.Label data-browse="Button Text" htmlFor="releaseImage">
                            Upload Album Art
                          </Form.File.Label>

                        </Form.File>

                      </Form.Row>
                    </div>
                    <div className={styles['release-price-container']}>
                      <Form.Label>
                        Price per track
                        {touched.price && errors.price ?
                          <span className={styles['form-error']}>
                            {errors.price}
                          </span> :
                          null}
                      </Form.Label>
                      <div className={styles['release-price']}>
                        <span className={styles['dollar-sign']}>$</span>
                        <Form.Control
                          id="price"
                          name="price"
                          type="number"
                          isInvalid={touched.price && errors.price}
                          value={values.price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        /></div>
                    </div>
                    <div className={styles['release-splits']}>

                      <Form>
                        <Form.Check
                          type="radio"
                          id="project-split"
                          label="Use Project Split"
                          checked={!useCustomSplit}
                          onChange={() => {
                            setUseCustomSplit(false)
                            setFieldValue('split', initialProject.split)
                          }
                          } />

                        {!useCustomSplit && initialProject && initialProject.split ?
                          <div className={styles['project-split-display']}>
                            {initialProject.split.map(payee => {
                              return <div>
                                <span>{payee.name} |</span>
                                <span> {payee.paypalEmail} |</span>
                                <span> {payee.percent}% </span>

                              </div>
                            })}
                          </div> : null}


                        <Form.Check
                          type="radio"
                          id="custom-split"
                          label="Custom Split"
                          checked={useCustomSplit}
                          onChange={() => {
                            setUseCustomSplit(true)
                            if (initialRelease && !initialRelease.split) {
                              let newValues = { ...values }
                              newValues["split"] = [{
                                name: '',
                                paypalEmail: `${props.user.email}`,
                                percent: '100'
                              }]
                              setValues(newValues)
                            }
                          }} />


                      </Form>
                      {useCustomSplit ?
                        <SplitInput
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          splitValuesPath={values.split}
                          splitNamePath={`split`}
                          splitErrorsPath={errors.split}

                        />
                        : null}


                    </div>
                  </div>
                  <div className={styles['right-pane-release']}>

                    <FieldArray
                      name="works"
                      render={arrayHelpers => (<>
                        <div className={styles['file-drop-container']}>
                          {/* @@ Needs refactoring- passing values and setFieldValue through FileDropper is ugly */}
                          <FileDropper
                            formikFileSetter={formikFileSetter}
                            values={values}
                            setFieldValue={setFieldValue}
                          /></div>
                        <div className={styles['enter-track-info-text']} onClick={() => {
                          setFieldValue('works', [{
                            slug: '',
                            songFile: '',
                            title: '',
                            trackNo: '',
                            price: initialProject && initialProject.price ? initialProject.price : '',
                            split: initialRelease ? initialRelease.split : initialProject ? initialProject.split : [{ name: '', paypalEmail: '', percent: '100' }]
                          }])
                        }}>{values.works.length > 0 ? null : "or enter track info manually"}</div>
                        <div className={styles['tracks-container']}>
                          <div hidden={values.works.length === 0} className={styles['release-create-work-row-header']}>
                            <Form.Label>Track Title</Form.Label>
                            <Form.Label>Price</Form.Label>
                          </div>
                          {values.works ? values.works.map((work, index) => {
                            return (<div className={styles['release-create-work-row']}>
                              <span className={styles['work-title-container']}>
                                <span className={styles['track-number']}>
                                  {index + 1}
                                </span>
                                <span>
                                  <Form.Control
                                    id="work-title"
                                    className={styles['release-create-work-title']}
                                    name={`works[${index}].title`}
                                    type="text"
                                    isInvalid={fieldArrayValidator('works', 'title', errors, index)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.works[index].title}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Invalid
                              </Form.Control.Feedback>
                                </span>
                              </span>

                              <span className={styles['price-container']}>
                                <span className={styles['dollar-sign']}>
                                  $</span>
                                <Form.Control
                                  id="work-price"
                                  className={values.works[index].price === values.price ?
                                    styles['release-create-work-price-grey'] :
                                    styles['release-create-work-price']}
                                  name={`works[${index}].price`}
                                  type="number"
                                  isInvalid={fieldArrayValidator('works', 'price', errors, index)}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.works[index].price}
                                /></span>

                              {values.works.length - 1 === index ?
                                <div className={styles['track-button']}
                                  onClick={() => arrayHelpers.push({
                                    slug: '',
                                    songFile: '',
                                    title: '',
                                    trackNo: '',
                                    price: values.price ?
                                      values.price :
                                      '',
                                    split: values.split ? values.split : initialRelease.split ? initialRelease.split : initialProject.split ? initialProject.split : [{ name: '', paypalEmail: '', percent: '100' }]
                                  })} >
                                  <FontAwesomeIcon icon={faPlus} />
                                </div>
                                : !editMode ?
                                  <div className={styles['track-button']} onClick={() => arrayHelpers.remove(index)} >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </div> : null}
                              <Button
                                className={`${styles['button-fando']} ${styles['button-small']}`}
                                onClick={() => {

                                  setSplitModalShow({ show: true, index: index })

                                }}
                                hidden={!work.title}
                              >
                                EDIT SPLIT
                            </Button>
                              <span>{((work.split &&
                                initialRelease &&
                                (Object.keys(diff(initialRelease.split, work.split)).length === 0)) ||
                                (work.split &&
                                  !initialRelease &&
                                  (Object.keys(diff(values.split, work.split)).length === 0))) && 
                                  (Object.keys(diff(initialProject?.splitRef, initialRelease?.splitRef)).length > 0) ?
                                <span style={{ color: `#858585` }}>R</span> :
                                work.split &&
                                  initialProject &&
                                  (Object.keys(diff(initialProject.split, work.split)).length === 0) ?
                                  <span style={{ color: `#bbb` }}>P</span> :
                                  "C"}</span>

                              {splitModalShow.index === index ? <SplitModal
                                values={values}
                                touched={touched}
                                errors={errors}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                show={splitModalShow}
                                title={work.title}
                                splitValuesPath={values.works[index].split}
                                splitNamePath={`works[${index}].split`}
                                splitErrorsPath={errors.works && errors.works[index] ? errors.works[index].split : null}
                                onHide={() => setSplitModalShow(false)}
                              /> : null}
                            </div>)
                          }) : null}

                        </div>
                      </>)
                      }
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className={styles['modal-footer']}>
                  <Button
                    disabled={!isValid}
                    className={styles['button-fando']}
                    type="submit">SAVE RELEASE</Button>
                  <Button
                    disabled={!isValid}
                    className={`${styles['button-fando']} ${isValid ?
                      styles['button-highlight-valid'] :
                      null}`}
                    type="submit"
                    onClick={setPublish(true)}>SAVE RELEASE AND PUBLISH</Button>
                </Modal.Footer>
              </FormikForm>
              )
            }} />
        </div>





      </Modal>
    </div>
  </>

}