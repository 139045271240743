import React, { useEffect, useState, useRef } from 'react';

import styles from './Profile.module.css'
import * as firebase from 'firebase'
import firebaseApp, { firestore as db } from '../firebase.js'
import { clearanceState } from "../enums.js"
import LicenseTable from './LicenseTable.js'
import OrdersTable from './OrdersTable'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'


function Profile({ match, user }) {
  const [licenses, setLicenses] = useState();
  let [urlUpdated, setUrlUpdated] = useState([]);
  let profile = '';
  const [licenseArray, setLicenseArray] = useState();

  const storage = firebaseApp.storage()

  useEffect(() => {
    if (user) {
      let docRef = db.collection('licenses').where('buyer', '==', user.uid).orderBy('dateCreated', 'desc')
      docRef.get().then(function (querySnapshot) {
        const licenseArray = []
        querySnapshot.forEach(doc => {
          licenseArray.push({id: doc.id, data: doc.data()})
        })
        setLicenses(licenseArray)
      }).catch(function (error) {
        console.log("Error getting documents", error);
      })
    }
  }, [user, urlUpdated])

  useEffect(() => {
    if(licenses) {
      setLicenseArray(licenses.map((license, index) => {
        return <LicenseItem license={license} key={index} />
      }))}}, [licenses])

  function LicenseItem(props) {
    const [songLink, setSongLink] = useState()
    const inputRef = useRef();


    async function getDownloadLink() {
      try {
        const workDocRef = props.license.data.work

        let workDoc = await workDocRef.get()
        let releaseSlug = workDoc.ref.parent.parent.id
        let projectSlug = workDoc.ref.parent.parent.parent.parent.id
  
        let work = workDoc.data()
        let workSlug = work.slug
        let filename = work.songFile
  
        const songDownloadRef = storage.ref(['projects', projectSlug, 'releases', releaseSlug, 'workFiles', workSlug, filename].join('/'))
        let songDownload = await songDownloadRef.getDownloadURL()
        setSongLink(songDownload)
      } catch(error) { console.error(error) }
      

    }

    getDownloadLink()


    const urlSubmitter = (e) => {
      e.preventDefault();
      console.log(inputRef.current.value)
      let docRef = db.collection('licenses').doc(props.license.id)
      let timestamp = firebase.firestore.Timestamp.fromMillis(Date.now())
      const newvals = {
        url: inputRef.current.value,
        clearance: clearanceState.assigned,
        dateAssigned: timestamp
      }
      return docRef.update(newvals)
                   .then(function() {
                     setUrlUpdated(urlUpdated => [...urlUpdated, props.license.id])
                     setUrlDisplay(props.license.data.url);
                     console.log("Document Successfully Updated");
                   }).catch(function(error) {
                     console.log("Error updating document: ", error )
                   })
    }

    const initialUrlDisplay = (props.license.data.url ? props.license.data.url : <form onSubmit={e => urlSubmitter(e)} style={{height: `18px`}}><input ref={inputRef} type="text" name="url" placeholder="Paste YouTube URL" style={{height: `18px`}} ></input><Button variant="outline-secondary" type="submit" size="sm" style={{height: `16px`, padding: `0`, fontSize: `10px`, marginLeft: `4px`}} >Submit</Button></form>)

    const [urlDisplay, setUrlDisplay] = useState(initialUrlDisplay)
    console.log(props.license)
    var utcSeconds = props.license.data.datePurchased.seconds
    var d = new Date(0)
    d.setUTCSeconds(utcSeconds)
    return (
      <tr key={props.license.id}>
        <td>{props.license.data.projectName}</td>
        <td>{props.license.data.workTitle}</td>
        <td style={{textAlign: `center`}} width='12px'><a href={songLink}><FontAwesomeIcon icon={faDownload} /></a></td>
        <td>{props.license.data.releaseName}</td>
        <td>{d.toLocaleDateString()}</td>
        <td>{urlDisplay}</td>
      </tr>
    )
  }

  if (!user) {
    profile = <Card.Title style={{ gridColumn: `2 / 3`, gridRow: `1 / 2`, fontWeight: `bold` }}>Please sign in!</Card.Title>
  } else {
    let userDate;

    function dateMaker() {
      let d = new Date(user.metadata.creationTime);
      userDate = d.toDateString()
    }

    dateMaker();

    profile = (<React.Fragment>
      <Image src={user.photoURL} style={{ width: `180px` }} roundedCircle></Image>
      <div style={{ gridColumn: `2 / 4`, gridRow: `1 / 2`, borderBottom: `1px solid #eee` }}>
        <Card.Title style={{ fontWeight: `bold` }}>{user.displayName}</Card.Title>
        <Card.Text style={{ gridColumn: `2 / 3`, gridRow: `1 / 2` }}>{user.email}</Card.Text>
        <Card.Text style={{ gridColumn: `2 / 3`, gridRow: `1 / 2`, color: `#999` }}>User since: {userDate}</Card.Text>
      </div>
      <div className={styles['tab-container']} style={{ gridColumn: `1 / 4`, gridRow: `2 / 3`, marginTop: `18px` }}>
        <Tabs defaultActiveKey="licenses">
          <Tab eventKey="licenses" title="Licenses">
            <LicenseTable licenseArray={licenseArray} />
          </Tab>
          <Tab eventKey="orders" title="Order History">
            <OrdersTable user={user} />
          </Tab>
        </Tabs>
      </div>
    </React.Fragment>)
  }

  return (<Card style={{ display: `grid`, gridTemplateColumns: `240px 1fr 1fr`, marginTop: `24px`, width: `90%`, marginLeft: `auto`, marginRight: `auto`, padding: `12px` }}>
    {profile}
  </Card>)
}

export default Profile;
