import React from 'react'
import Table from 'react-bootstrap/Table'


function LicenseTable(props) {
    return (
        <Table style={{ fontSize: `.8rem`, borderBottom: `2px solid #FF2800` }} size="sm" striped bordered hover>
            <thead>
                <tr>
                    <th>Artist</th>
                    <th>Song</th>
                    <th>Download</th>
                    <th>Album</th>
                    <th>Purchased</th>
                    <th>URL(s)</th>
                </tr>
            </thead>
            <tbody>
                {props.licenseArray}
            </tbody>
        </Table>
    )
}


export default LicenseTable;
