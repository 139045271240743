import React from 'react';
import useGlobalHook from 'use-global-hook';

const storedCart = JSON.parse(localStorage.getItem('cart'));
const initialState = {
  cart: storedCart ? storedCart : [],
  user: null
};

const actions = {
  cart: {
    addItem: (store, purchase) => {
      const cart = store.state.cart
      console.log(cart)
      const newCart = cart.concat([purchase])
      store.setState({ cart: newCart });
      localStorage.setItem('cart', JSON.stringify(newCart));
    },
    clear: (store) => {
      store.setState({ cart: [] })
      localStorage.removeItem('cart')
    },
    deleteItem: (store, path) => {
      const cart = store.state.cart
      const newCart = cart.filter(work => work.path !== path)
      store.setState({ cart: newCart })
      localStorage.setItem('cart', JSON.stringify(newCart));
    },
  },
  user:{
    setUser: (store, user) => {
      store.setState({user: user})
    },
    setToken: (store, token) => {
      store.user.token = token
      store.setState({user: store.user})
    }

  }
}

const useGlobal = useGlobalHook(React, initialState, actions);
const Use = () => {
  let [state, actions] = useGlobal()
  return {state: state, actions: actions}
}
const store = {
  use: Use,
  useGlobal: useGlobal,
  session: sessionStorage,
  local: localStorage,
  fire: {}
}

export {
  store as default,
  useGlobal
}
