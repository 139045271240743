import React, { useState, useRef } from 'react'
import './chrome.css'
import * as cart from './cart.js'

import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestion } from '@fortawesome/free-solid-svg-icons'

import { Link } from "react-router-dom"

import { withRouter } from 'react-router'
import * as store from "./store.js";


function FandoNav({ user, signOut, signInWithGoogle, location, history }) {
  const [state] = store.useGlobal();
  const [cartPopShow, setCartPopShow] = useState(false);
  const [profPopShow, setProfPopShow] = useState(false);
  const profTarget = useRef()
  let initials = ""


  const [hideOnScroll, setHideOnScroll] = useState(true)

  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y >= 0
    if (isShow !== hideOnScroll) setHideOnScroll(isShow)
  }, [hideOnScroll])

  function profPopShower() {
    setCartPopShow(false);
    setProfPopShow(!profPopShow);
  }

  function cartPopShower() {
    setProfPopShow(false);
    setCartPopShow(!cartPopShow);
  }

  function photoChecker() {
    if (state.user) {
      if (state.user.photoURL.indexOf("photo.jpg") === -1) {
        initials = (state.user.displayName.match(/\b(\w)/g).join(''))
      }
    }
  }

  photoChecker();


  // @@ does conditional creation of variable eliminate unnecessary rendering?
  const cartWidget = (
    <Nav.Item>
      <cart.Cart cart={state.cart} cartPopShower={cartPopShower} cartPopShow={cartPopShow} setCartPopShow={setCartPopShow} />
    </Nav.Item>
  )

  const userWidget = <Nav.Item>
    {user
      ?
      <>
        <div ref={profTarget} onClick={() => profPopShower()} style={{ display: `inline-block`, position: `relative`, marginLeft: `8px`, width: `46px`, cursor: `pointer` }}>
          <Image style={{ border: `1.5px solid #222`, width: `100%`, cursor: `pointer` }} src={user.photoURL} height="46px" roundedCircle />
          <div style={{ position: `absolute`, top: `50%`, left: `50%`, transform: `translate(-50%, -50%)`, color: `white`, fontWeight: `bold`, userSelect: `none` }}>{initials}</div>
          <div style={{ textAlign: `right`, fontSize: `9px`, margin: `-11px -4px 0 auto`, color: `#000` }}>▼</div>
        </div>
        <Overlay target={profTarget.current} placement="bottom-end" show={profPopShow}>
          {props => (<Popover {...props} style={{ width: `400px`, marginRight: `-6px`, padding: `18px`, ...props.style }}>
            <Popover.Title style={{ margin: `-18px -18px 0 -18px` }} as="h3">{state.user.displayName} </Popover.Title>
            <div style={{ paddingTop: `12px`, paddingBottom: `12px` }}>
              <h6><Link to={`/profile`}>Profile</Link></h6>
              <h6><Link to={`/profile`}>Licenses</Link></h6>
              <h6><Link to={`/profile`}>Orders</Link></h6>
            </div>
            <Button size="sm" variant="outline-dark" onClick={signOut}>Log out</Button>
          </Popover>)}
        </Overlay>
      </>
      : <Button size="sm" variant="outline-dark" onClick={async () => {
        signInWithGoogle()
          if (location.pathname === '/') {
            console.log("Redirecting...")
            history.push('/~dash')
        }
        
        
      }}>{location.pathname === '/' ? "Artist Sign In" : "Sign In"}</Button>}
  </Nav.Item>;

const dashLink = <Nav.Item>
  <Link to={'/~dash'}>MANAGE/ADD PROJECTS</Link>
</Nav.Item>

  return (
    <Navbar style={{
      padding: `0`,
      boxShadow: hideOnScroll ? `-10px 10px 15px -11px rgba(0,0,0,0.26)` : `none`,
      backgroundColor: !hideOnScroll ? `rgba(255,255,255,0)` : `rgba(255,255,255,1)`,
      transition: `all .2s`
    }}
      expand="sm" sticky="top" >
      {hideOnScroll ? <Navbar.Brand href="/">
        <span style={{
          paddingLeft: `1em`,
          fontSize: `80%`,
          fontWeight: `bold`
        }}>
          <span>FANDO</span>
          <span style={{ color: `#FF2800` }}>MUSIC</span>
        </span>
      </Navbar.Brand> : null}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <span style={{
          padding: `2px 6px`,
          background: `rgba(255,255,255,.85)`,
          boxShadow: !hideOnScroll ? `-10px 10px 15px -11px rgba(0,0,0,0.26)` : `none`,
          borderBottomLeftRadius: `6px`
        }}>
          <Nav>
            {location.pathname !== '/checkout' && location.pathname !== '/' && state.cart.length > 0 && cartWidget}
            {location.pathname !== '/' || (location.pathname === '/' && !user) ? userWidget : dashLink}
          </Nav>
        </span>
      </Navbar.Collapse>
    </Navbar>
  )
}

function Footer() {
  return (
    <footer id="footer" className="bg-white">
      <div className="container">
        <div className="navbar navbar-expand-md ">
          <div className="footer-faq">
            <Link className="nav-link" to="/~faq">
              <span className="d-inline-block">
                <FontAwesomeIcon icon={faQuestion} />
              </span>
              <p className="d-inline-block">FAQ</p>
            </Link>
          </div>
          <div className="navbar-collapse justify-content-end">
            <ul className="navbar-nav footer-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/~privacy-policy">Privacy Policy</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/~terms-of-service">Terms of Service</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/~contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

const WrappedNav = withRouter(FandoNav)

export {
  WrappedNav as Nav,
  Footer
}
