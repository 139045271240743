import React, { useState } from 'react'

import styles from './Dashboard.module.css'
import fieldArrayValidator from './fieldArrayValidator'

import { FieldArray, Field } from 'formik'

import Form from 'react-bootstrap/Form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

export default function SplitInput({ values,
  errors,
  handleChange,
  handleBlur,
  splitValuesPath,
  splitNamePath,
  splitErrorsPath, }) {
  // console.log(splitValuesPath)
  const [showX, setShowX] = useState({ show: false })


  function validatePercentages(value) {
    let error;

    if (!value) {
      error = 'Required'
    }

    if ((value < 1 || value > 100)) {
      error = 'Must be between 1-100'
    }

    let percSum = 0;
    splitValuesPath.forEach(split => {
      percSum = percSum + parseInt(split.percent)
    })
    if (percSum !== 100) {
      error = 'must add up to 100%'
    }
    console.log(percSum)
    console.log(error)
    return error
  }

  return <FieldArray name={splitNamePath} render={arrayHelpers => (<div className={styles['right-pane-splits']}>
    {splitValuesPath ? splitValuesPath.map((payee, index) => (
      <div className={styles['split-row']}
        key={index}
        onMouseEnter={() => setShowX({ show: true, index: index })}
        onMouseLeave={() => setShowX({ show: false })}>
        <div
          title="Delete"
          style={showX.show && showX.index === index ? { fontWeight: `bold`, color: `#888`, cursor: `pointer` } : { fontWeight: `bold`, opacity: `0%` }}
          onClick={() => arrayHelpers.remove(index)}>
          X
                </div>
        <div className={styles['payee-name']}>
          <Form.Label htmlFor={`payee-name`}>
            Name
                                {fieldArrayValidator('split', 'name', errors, index) ?
              <span className={styles['form-error']}>* {splitErrorsPath[index].name}</span> : null} </Form.Label>
          <Form.Control id={`payee-name`}
            name={splitNamePath + `[${index}].name`}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={fieldArrayValidator('split', 'name', errors, index)}
            value={splitValuesPath[index].name} placeholder="" />
        </div>

        <div className={styles['email']}>
          <Form.Label htmlFor={`payee-email`}>
            Paypal E-mail
                            {fieldArrayValidator('split', 'paypalEmail', errors, index) ?
              <span className={styles['form-error']}>* {splitErrorsPath[index].paypalEmail}</span> :
              null} </Form.Label>
          <Form.Control id={`payee-email`}
            name={splitNamePath + `[${index}].paypalEmail`}
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={fieldArrayValidator('split', 'paypalEmail', errors, index)}
            value={splitValuesPath[index].paypalEmail} placeholder="Type PayPal e-mail" />
        </div>
        <div className={styles['percent']}>
          <Form.Label htmlFor={`payee- percent`}>
            Percentage
                                {fieldArrayValidator('split', 'percent', errors, index) ?
              <span className={styles['form-error']}>
                * {splitErrorsPath[index].percent}
              </span> : null}</Form.Label>

          <Field name={splitNamePath + `[${index}].percent`}
            validate={validatePercentages}>
            {({
              field, // { name, value, onChange, onBlur }
              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
              meta,
            }) => (
                <Form.Control
                  id={`payee-percent`}
                  name={splitNamePath + `[${index}].percent`}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={fieldArrayValidator('split', 'percent', errors, index)}
                  value={splitValuesPath[index].percent}
                  placeholder="1-100%"
                  {...field}
                />
              )}
          </Field>
        </div>
        {splitValuesPath.length - 1 === index ?
          <div className={styles['split-button']} onClick={() => arrayHelpers.push({
            name: '',
            paypalEmail: '',
            percent: '',
          })}>
            <FontAwesomeIcon icon={faPlus} />
          </div>
          : <FontAwesomeIcon icon={faPlus} style={{ opacity: `0%` }} />
        }
      </div>)) : null}
  </div>)} />



}