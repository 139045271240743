import React, { useState, useEffect } from 'react'
import { Formik, Field, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import styles from '../Dashboard.module.css'

import firebaseApp, { firestore as db } from '../../firebase'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

import placeholder from '../../images/album-art-placeholder.png'

import SplitInput from '../SplitInput'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGuitar } from '@fortawesome/free-solid-svg-icons'



var expression = /^[a-z0-9]+(?:-[a-z0-9]+)*$/
var urlRegex = new RegExp(expression);


async function validateSlug(value) {
  let error;


  if (!value) {
    error = 'Required'
  } else if (!value.match(urlRegex)) {
    error = 'Invalid'
  } else if (value.length < 2) {
    error = 'Too short'
  } else if (value.length > 50) {
    error = 'Too long'
  } else {
    let docRef = db.collection('projects').doc(value)
    await docRef.get()
      .then(function(doc) {
        if (doc.exists)
          error = 'This URL is taken'
      })  
  }

  return error
}

const ProjectSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too short')
    .max(50, 'Too Long')
    .required('Required'),
  bio: Yup.string(),
  price: Yup.number()
    .positive()
    .required('Invalid'),
  split: Yup.array().of(Yup.object().shape({
    paypalEmail: Yup.string()
      .email('Invalid')
      .required('Required'),
    role: Yup.string(),
    percent: Yup.number()
      .positive()
      .min(1, 'Invalid')
      .max(100, 'Invalid')
      .required('Required')
  }))

})


export default function ProjectModal(props) {
  const [newProjectImage, setNewProjectImage] = useState()
  const [newBannerImage, setNewBannerImage] = useState()
  const [projectImage, setProjectImage] = useState()
  const [bannerImage, setBannerImage] = useState()

  let modalForward = false;
  const storage = firebaseApp.storage()

  let project = props.project
  let setLoading = props.setLoading



  useEffect(() => {

    async function fetchImages() {
      setLoading(true)
      try {
        const bannerImageRef = storage.ref(['projects', project.slug, 'banner_image.jpg'].join('/'))
        let url = await bannerImageRef.getDownloadURL()
        setBannerImage(url)
      } catch {
        console.log("No Banner Image Found")
      }

      try {
        const projectImageRef = storage.ref(['projects', project.slug, 'project_image.jpg'].join('/'))
        let url = await projectImageRef.getDownloadURL()
        setProjectImage(url)
      } catch {
        console.log("No Project Image Found")
      }

      setLoading(false)
    }

    if (project) {
      fetchImages()
    } else {
      setProjectImage()
      setBannerImage()
    }

    return () => {
      setLoading(false)
      setProjectImage()
      setBannerImage()
    }
  }, [props.show, setLoading, storage, project])

  let initialValues
  let editMode = false

  if (project) {
    editMode = true
    console.log(project)

    initialValues = {
      name: project.name,
      slug: project.slug,
      bio: project.bio ? project.bio : '',
      price: project.price,
      split: project.split ? project.split : [{ name: '', paypalEmail: `${props.user.email}`, percent: '100' }]
    }
  } else {
    initialValues = {
      name: '',
      slug: '',
      bio: '',
      price: '',
      split: [{ name: '', paypalEmail: `${props.user.email}`, percent: '100' }]
    }
  }


  return (<>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className={styles['modal-general']}>
        <Modal.Header className={styles['modal-header']} closeButton>
          <div className={styles['modal-title-left']}>
            <FontAwesomeIcon className={styles['guitar-icon']} icon={faGuitar} />
            <h4>{editMode ? "EDIT PROJECT" : "ADD A PROJECT"}</h4>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={ProjectSchema}
          onSubmit={async values => {

            //UPLOAD IMAGES
            async function uploadFiles() {
              if (newProjectImage) {
                let projectImageRef = storage.ref(['projects', values.slug, 'project_image.jpg'].join('/'))
                await projectImageRef.put(newProjectImage).then(function (snapshot) {
                  console.log("Uploaded Profile Image!")
                }).then(() => {
                  setNewProjectImage()
                  if (!bannerImage) {
                  }
                }).catch(error => {
                  console.error("Something bad happened with project Image upload", error)
                })
              }

              if (newBannerImage) {
                let bannerImageRef = storage.ref(['projects', values.slug, 'banner_image.jpg'].join('/'))
                await bannerImageRef.put(newBannerImage).then(function (snapshot) {
                  console.log("Uploaded Banner Image!")
                }).then(() => {
                  setNewBannerImage()
                })
                  .catch(error => {
                    console.error("Error with Banner Image upload", error)
                  })
              }
            }



            async function pushProject() {
              let newProject = {
                name: values.name,
                managers: [props.user.uid],
                slug: values.slug,
                bio: values.bio,
                price: values.price,
                split: values.split,
              }
              db.collection('projects').doc(values.slug).set(newProject, { merge: true })
                .catch(error => { console.error(error) })
                .then(() => {
                  console.log("Project successfully written!")
                })
                .then(async () => {
                  let splitPayees = []
                  await Promise.all(values.split.map(async payee => {

                    let payeeRef = db.collection('payees').doc(payee.paypalEmail)
                    payeeRef.set({ name: payee.name, paypalEmail: payee.paypalEmail }, { merge: true })
                    splitPayees.push({ percent: payee.percent, payee: payeeRef }) //CHANGE PROPERTY TO payeeRef ONCE BACK-END IS IN AGREEMENT
                  }

                  )
                  )
                  return splitPayees
                })
                .catch(error => console.error(error))
                .then(async (splitPayees) => {
                  // let splitPayeesObject = Object.assign({}, splitPayees)
                  const splitRef = db.collection('projects').doc(values.slug).collection('splits').doc()

                  await splitRef.set({ type: "project", payees: splitPayees })
                  newProject = { ...newProject, splitRef: splitRef }
                  db.collection('projects').doc(values.slug).set({ splitRef: splitRef }, { merge: true })
                  if (modalForward) {
                    props.setReleaseModalShow({ project: newProject, show: true })
                  }
                })
                .then(() => {
                  console.log("Payees successfully written!")
                })

            }

            props.setLoading(true)
            await uploadFiles()
              .then(() => {
                pushProject()
              }).then(() => {
                props.onHide()
              }).then(() => {
                if (!projectImage && !bannerImage) {
                  props.setLoading(false)
                }
              }).catch(error => console.error(error))

          }}
          render={({ values,
            errors,
            touched,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit }) => (
              <FormikForm className={styles['form']} onSubmit={handleSubmit}>
                <Modal.Body className={styles['modal-body']}>


                  <div className={styles['left-pane']}>
                    <Form.Label
                      style={errors.name && touched.name ? { color: `red` } : null}
                      htmlFor="name">
                      Project Name
                    </Form.Label>
                    <Form.Control
                      id="name"
                      name="name"
                      type="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && errors.name}
                      value={values.name}
                      placeholder="Type your project name"
                    />
                    {errors.name && touched.name ?
                      <div className={styles['form-error']}>* {errors.name}</div> :
                      null}
                    <Form.Label
                      style={errors.slug && touched.slug ?
                        { color: `red` } :
                        null}
                      htmlFor="slug">
                      URL (fandomusic.com/{values.slug})
                    </Form.Label>

                    <Field name="slug" validate={!editMode ? validateSlug : null}>
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                          <Form.Control
                            id="slug"
                            name="slug"
                            type="text"
                            disabled={editMode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={touched.slug && !errors.slug}
                            isInvalid={touched.slug && errors.slug}
                            value={values.slug}
                            placeholder="Customize the URL for your store"
                            {...field}
                          />
                        )}
                    </Field>
                    {errors.slug && touched.slug ? <div className={styles['form-error']}>* {errors.slug}</div> : null}
                    <Form.Label
                      style={errors.bio && touched.bio ?
                        { color: `red` } : null}
                      htmlFor="bio">
                      Tagline <em>- Optional</em>
                    </Form.Label>
                    <Form.Control
                      id="bio"
                      name="bio"
                      type="bio"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bio}
                      placeholder="Tell us a little about your project"
                    />
                    <Form.Label
                      style={errors.price && touched.price ?
                        { color: `red` } :
                        null} htmlFor="price">
                      Default Track Price
                    </Form.Label>
                    <Form.Control
                      id="price"
                      name="price"
                      type="number"
                      isValid={touched.price && !errors.price}
                      isInvalid={touched.price && errors.price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.price}
                    />
                    {errors.price && touched.price ?
                      <div className={styles['form-error']}>* {errors.price}</div> :
                      null}
                    <Form.Row className={styles['file-inputs']}>
                      <Form.File as={Col} id="projectImage" className={styles['form-file-release-art']} custom>
                        <Form.File.Input
                          name="projectImage"
                          onChange={(event) => { setNewProjectImage(event.currentTarget.files[0]) }}
                          value={values.projectImage}
                        />
                        <Form.File.Label
                          data-browse="Button Text"
                          htmlFor="projectImage">
                          Project Image
                        </Form.File.Label>

                      </Form.File>
                      <Form.File as={Col} id="bannerImage" custom>
                        <Form.File.Input
                          name="bannerImage"
                          onChange={(event) => { setNewBannerImage(event.currentTarget.files[0]) }}
                          value={values.bannerImage}
                        />
                        <Form.File.Label data-browse="Button Text" htmlFor="bannerImage">Banner Image</Form.File.Label>

                      </Form.File>
                    </Form.Row>
                    <div className={styles['project-image-container']}>
                      <Image
                        roundedCircle
                        src={newProjectImage ?
                          URL.createObjectURL(newProjectImage) :
                          projectImage ?
                            projectImage :
                            placeholder}
                        alt="banner" />
                    </div>
                    <div className={styles['banner-image-container']}>
                      <Image
                        src={newBannerImage ?
                          URL.createObjectURL(newBannerImage) :
                          bannerImage ?
                            bannerImage :
                            placeholder}
                        alt="banner" />
                    </div>
                  </div>
                  <div className={styles['right-pane']}>
                    <div className={styles['right-pane-header']}>
                      <h6>Default Project Split</h6>
                      <p>Tell us who is involved and how they'll get paid</p>
                    </div>


                    {
                      <SplitInput
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        splitValuesPath={values.split}
                        splitNamePath={`split`}
                        splitErrorsPath={errors.split}
                      />}
                  </div>
                </Modal.Body>
                <Modal.Footer className={styles['modal-footer']}>
                  <Button disabled={!isValid} className={styles['button-fando']} type="submit">SAVE PROJECT</Button>
                  <Button disabled={!isValid} className={`${styles['button-fando']} ${isValid ? styles['button-highlight-valid'] : null}`} type="submit" onClick={() => modalForward = true}>SAVE PROJECT AND ADD RELEASE</Button>
                </Modal.Footer>
              </FormikForm>
            )}
        />
      </div>
    </Modal>
  </>)
}

