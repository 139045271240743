import React from 'react'

import styles from '../Dashboard.module.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import SplitInput from '../SplitInput'

export default function SplitModal(props) {

    return <Modal
        {...props}
        size="med"
        aria-labelledby="contained-modal-title-vcenter"
        className={styles['split-modal']}
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title>EDIT SPLIT FOR {props.title ? props.title : "NO TITLE"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles['modal-body']}>
            <SplitInput
                {...props}
                isModal />
            <Modal.Footer className={styles['modal-footer']}>
                <Button
                    className={styles['button-fando']}
                    onClick={() => props.onHide()}>SAVE</Button>
            </Modal.Footer>

        </Modal.Body>
    </Modal>
}