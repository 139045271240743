import React from 'react'
import styles from './fando-general-stuff.module.css'

import Card from 'react-bootstrap/Card'

export default function Faq() {
  return <Card className={styles['container-card']}>
    <div>
      <h1>Frequently Asked Questions</h1>
      <h3>Creator FAQ</h3>
    </div>
    <div>
      <h4>How do I create an account?</h4>
      On the FandoMusic home page, select “Sign Up” in the top right of the site and authenticate using your Google Account.
      </div>
      <div>
        <h4>    What kind of licenses or clearances does FandoMusic provide?</h4>
      FandoMusic grants a Synchronization/Master Use License. A Synchronization/Master Use License allows music to be used in timed relation to moving picture. A moving picture can be defined as a slideshow, video or a film.
      </div><div>
        
      <h4>    Where can I download my track?</h4>
      When you are logged into your account, select the main menu located in the upper left hand corner of the site and go to “Licenses”. There will be a download button next to the song. You can also follow the download link in your email order confirmation.
      </div>
    <div>
      <h4> What type of audio files do I get?</h4>
      High quality file download is coming soon: once a license has been purchased, the file types available for download are: 320 kbps mp3, AIFF and WAV.
    </div>
    <div>
      <h4> Do I need to enter a video link?</h4>
      Yes, your video link is very required to validate your license. Without assigning a link to your license, we cannot clear your video. To assign a video, go to your profile page and enter it beside the appropriate license.
    </div>
    <div>
      <h4>    Can I edit the music once I purchase a license?</h4>
      As long as the elements of the track remains unchanged you can edit the song to fit your project. Adding additional musical elements or remixing the original song sync use requires permission.
    </div>
    <div>
      <h4>    Once I license your music, how long is my license good for?</h4>
      Licenses purchased from the FandoMusic website are granted for a perpetual term. This means that the license never expires or loses legitimacy specific to the original project for which the license was granted. Should you desire to use a song licensed to you by FandoMusic for another project, a separate license will be required.
    </div>
    <div>
      <h4>Can I monetize my video?</h4>
      Yes! FandoMusic will whitelist your video to prevent ContentID claims from the Artist or Label you licensed the music from. Most of the time, those claims happen automatically, and our system will respond quickly to get your video claims removed if they manage to get through. Providing your video link before going public is a critical step.
    </div>
    <div>
      <h4>I have received a copyright claim on my video. What does this mean and can it be cleared?</h4>
      FandoMusic is a full-service, rights-managed, music licensing platform. We are not a royalty-free platform and we don’t own any of the music that we offer for licensing outright; the artists do! We have a system to clear copyright claims, and will work alongside the Artist’s music you licensed to remove those claims.
    </div>
    <div>
      <h4>Do I need to credit the artist or FandoMusic?</h4>
      Crediting is optional, but it’s a great way to show appreciation to the Artist you love (in addition to using their music). And by purchasing a license through FandoMusic, you can say that you got permission straight from the Artist when you credit them;
      Song Title licensed directly from Artist via FandoMusic.
    </div>


    <div>
      <h4>    Does my license allow me to edit cut down versions?</h4>
      A single use (single song) license pertains to one single edit. Each license covers one “sync,” or one edit of the song being synced to motion picture. If a cut down creates a new version or edit with a new sync, this is considered a separate project and will need its own license. Lifts, however, are covered by one license. A lift is when a section of the video is “lifted out” of the project. This means that the project was not re-edited in any way and the music was not re-synced. An example of a lift would be placing the first 15 seconds of a video on social media - the video is identical to the original in the sync and the edit.
    </div>

    <div>
      <h4>    How Does YouTube Monetization Work?</h4>
      Learn how to earn money through your YouTube Channel by joining the YouTube Partner Program and optimizing your content to bring in much-coveted advertising revenue.
      If I'm publishing a video online, which websites am I able to upload to?
      The web/streaming distribution allows a video to be published anywhere online, as long as it's covered by the terms of your license agreement or subscription plan. For example, a project can be published on an organization's website, on a UGC (user generated content) site, including but not limited to YouTube, Facebook, Instagram, Twitch, Twitter, Patreon, Vimeo, or on any other websites.
    </div>
<div>
      <h3>Artists FAQ</h3>
      <h4>I’m an artist and want to sell licenses to my music via FandoMusic</h4>
  	  Right now, we’re recruiting artists as beta testers. Email us at <code>contact at fandomusic dot com</code> to talk to us about getting set up for with an Artist  account. Once the platform is officially launched, everyone will be able to sell licenses on FandoMusic, no approval or interview needed. FandoMusic's mission is to empower artists who have a need to offer licenses professionally, legally, and directly.
</div>

  </Card>
}
