import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useGlobal } from './store.js'
import firebase, { firestore as db } from './firebase.js'
import { TermsOfServiceEmbed } from './TermsOfService'
import styles from './Checkout.module.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { Link } from "react-router-dom"

import SpinnerOverlay from './SpinnerOverlay'


const functions = firebase.functions()

function CheckoutView({ user, setDetails, setLicenseCount, signInWithGoogle }) {
  const [state, globalActions] = useGlobal()
  const [works, setWorks] = useState([])
  const [spinner, setSpinner] = useState(false)
  let checkoutButton = "";

  // monetary values are stored as number of cents
  console.log(state)
  const total = works.map(work => { return work.price ? work.price : null })
    .reduce((a, b) => a + b, 0)
  console.log(total)

  useEffect(() => {
    async function fetchData() {
      const workRefs = state.cart.map(work => db.doc(work.path))
      var newWorks = workRefs.map(async ref => await ref.get())
      newWorks = await Promise.all(newWorks)
      newWorks = newWorks.map(doc => {
        var work = doc.data()
        work.id = doc.id
        return work
      })
      setWorks(newWorks)
    }
    fetchData()
  }, [state.cart])

  async function createOrder(data, actions) {
    setSpinner(true)
    const paypalCreateOrder = functions.httpsCallable('paypalCreateOrder')
    const workPaths = state.cart.map(work => work.path)
    const result = await paypalCreateOrder(workPaths)
    const orderId = result.data
    return orderId
  }

  async function onApprove(data, actions) {
    const paypalApproveOrder = functions.httpsCallable('paypalApproveOrder')
    const result = await paypalApproveOrder(data.orderID)
    const order = result.data
    setSpinner(false)
    setDetails(order.details)
    setLicenseCount(state.cart.length)
    globalActions.cart.clear()
  }

  let PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM })

  if (state.user) {
    checkoutButton = (<div class="payment mt-5">
      <PayPalButton
        createOrder={createOrder}
        onApprove={onApprove}
      />
    </div>)
  } else {
    checkoutButton = (<div class="payment mt-5">
      Please sign in before checking out:
    <Button size="sm" variant="outline-dark" onClick={signInWithGoogle}>Sign in</Button>
    </div>)
  }


  return (
    <>
      {spinner ? <SpinnerOverlay /> : null
      }
      <Card className={styles['container-card']}>
        <h2><strong>Checkout</strong></h2>
        {/* <div class="title">
        <h6>
          If you have the url for a video to clear, please enter it now with the license you are purchasing. You may also enter your video url for clearance after purchase.
        </h6>
      </div> */}
        <Table className={styles['work-table']} size="sm">
          <tbody>
            {state.cart.map(work =>
              <tr>
                <td>
                  {work.title}
                </td>
                {/* <div class="channel">
                <input id="inputUsername2" placeholder="Youtube Channel*" type="text" />
              </div> */}
                <td>
                  ${work.price ? work.price.toFixed(2) : "Free"}
                </td>
                <td>
                  <FontAwesomeIcon className={styles['delete-button']} onClick={() => globalActions.cart.deleteItem(work.path)} icon={faWindowClose} />
                </td>
              </tr>

            )}

            <tr style={{ fontSize: `125%`, fontWeight: `bold` }}>
              <td class="mr-2">Total</td>
              <td class="ml-5">${total.toFixed(2)}</td>
              <td></td>
            </tr>
          </tbody>
        </Table>

        <div>
          <div>
            <div>
              {/* <p>* We use Youtube Channel Number of Subscribers to determine the cost of licencing, please place your youtube channel id and follow the process in order to view our pricing.</p> */}
              <div className={styles['detail-text']}>We will send a confirmation of your license purchase(s) to your e-mail address. Once you have published a video using a licensed track, submit the video URL to its license in your <Link to="/profile">Fando profile.</Link> to activate the clearance process</div>
            </div>
            <div className={styles['agree-text']}>By clicking the Checkout button below, you agree to the following Terms Of Use:</div>
            <div style={{ fontSize: `70%`, width: `75%`, padding: `1.5em`, marginLeft: `0`, marginRight: `auto`, height: `16em`, overflow: `scroll`, overflowX: `hidden`, backgroundColor: `white`, border: `1px solid #ccc` }}>

              <TermsOfServiceEmbed />
            </div>
            <div style={{ display: `block` }} >
              <div class="price-wrap text-right col-md-4">

                {checkoutButton}
              </div>

            </div>
          </div>
        </div>
      </Card>
    </>
  )
}

function Confirm({ details, licenseCount }) {
  const amount = details.purchase_units[0].amount.value
  return (
    <div role="tabpanel" class="tab-pane" id="technica">
      <div class="confirmation-wrap">
        <div class="payment-title"> <span>PAYMENT RECEIPT</span>
          <h3>Thank you for your purchase, </h3>
          <p>A copy of your receipt has been sent to your email address</p>
        </div>
        <div class="download-wrap p-4">
          <div class="row">
            <div class="col-md-6">
              <div class="licenses-cost pr-5">
                <div class="licenses-cost-inner pr-3"> <img src="images/licenses.png" width="33" height="36" alt="" /> </div>
                <div class="licenses-cost-inner">
                  <p>LICENSES</p>
                  <span>{licenseCount}</span> </div>
              </div>
              <div class="licenses-cost">
                <div class="licenses-cost-inner pr-3"> <img src="images/cost.png" width="29" height="36" alt="" /> </div>
                <div class="licenses-cost-inner">
                  <p>TOTAL COST</p>
                  <span>${amount}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <Link to="/profile"><button type="button" class="btn btn-outline-primary w-100">View Licenses</button></Link>
            </div>
          </div>
        </div>
        <div class="invoice-id mt-5"><span>Invoice Id:</span><span class="id">{details.id}</span></div>
      </div>
    </div>
  )
}

function Checkout({ user, signInWithGoogle }) {
  const [details, setDetails] = useState()
  const [licenseCount, setLicenseCount] = useState(0)

  let component
  if (!details) {
    component = <CheckoutView user={user} setDetails={setDetails} setLicenseCount={setLicenseCount} signInWithGoogle={signInWithGoogle} />
  }
  else {
    console.log(details)
    component = <Confirm details={details} licenseCount={licenseCount} />
  }

  return (
    <section id="fm-checkout" class="middle-wrap">
      <div class="container">
        {component}
      </div>
    </section>
  )
}

export default Checkout
