import React from 'react'
import styles from './fando-general-stuff.module.css'

import Card from 'react-bootstrap/Card'

export default function TermsOfService() {
  return <Card className={styles['container-card']}>
    <TermsOfServiceEmbed />
  </Card>
}

export function TermsOfServiceEmbed() {
  return <>
<p dir="ltr" id="docs-internal-guid-98674eef-7fff-7fcb-131b-01b0958a0957"><strong>SOLSTICE, LLC d/b/a FANDO MUSIC</strong></p>
<p dir="ltr"><strong>Terms of Service 1.0</strong></p>
<p dir="ltr"><strong>August 5, 2020</strong></p>
<p dir="ltr"><strong>PLEASE READ THE TERMS OF SERVICE CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS RELATING TO THE USE OF SITE GENERALLY AND UPLOADING MUSIC TO THE SITE.</strong></p>
<p dir="ltr">These Terms of Service (this &ldquo;Agreement&rdquo; or &ldquo;Terms of Service&rdquo;) is a contract between you (&ldquo;you&rdquo; or &ldquo;User&rdquo;) and Solstice, LLC d/b/a Fando Music (&ldquo;Fando,&rdquo; &ldquo;we,&rdquo; or &ldquo;us&rdquo;) and any of our affiliates.&nbsp; You must read, agree to, and accept all of the terms contained in this Agreement to be a User, Seller, Buyer and/or Account Holder of our website located at www.fandomusic.com or any part of the rest of the site (the &ldquo;Site&rdquo; or the &ldquo;Fando Platform&rdquo;).</p>
<p dir="ltr">YOU UNDERSTAND THAT BY USING THE SITE OR SITE SERVICES, YOU AGREE TO BE BOUND BY THE TERMS OF SERVICE AS APPLICABLE TO YOU AND ANY AMENDMENTS THERETO.&nbsp; IF YOU DO NOT ACCEPT THE TERMS OF SERVICE IN ITS ENTIRETY, YOU MUST NOT ACCESS OR USE THE SITE SERVICES.&nbsp;</p>
<p dir="ltr">IF YOU AGREE TO THE TERMS OF SERVICE ON BEHALF OF AN ENTITY OR AGENCY, OR IN CONNECTION WITH PROVIDING OR RECEIVING SERVICES ON BEHALF OF AN ENTITY OR AGENCY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO BIND THAT ENTITY OR AGENCY TO THE TERMS OF SERVICE AND AGREE THAT YOU ARE BINDING BOTH YOU AND THAT ENTITY OR AGENCY TO THE TERMS OF SERVICE. IN THAT EVENT, &ldquo;YOU&rdquo;, &ldquo;YOUR&rdquo; AND &ldquo;USER&rdquo;, WILL REFER AND APPLY TO YOU AND THAT ENTITY OR AGENCY.</p>
<ol>
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>FANDO’S SITE GENERALLY</strong></p>
  </li>
</ol>
<ol type="a">
  <li dir="ltr">
    <p dir="ltr" role="presentation">The Fando Platform enables the discovery of music (&ldquo;Works&rdquo; or &ldquo;Songs&rdquo;) and the purchase and sale of licenses to use those Works in synchronization with video formats via the End User Agreement agreed to by the End User (all as defined within the End User Agreement).&nbsp; Said licenses are granted via the terms of the End User Agreement and the issuance of an End User License.&nbsp; The End User Agreement can be found here. Songs are included on the Fando Platform when submitted to us by an artist, label, publisher, distributor, sync agent or other person or entity representing the Song (&ldquo;Seller&rdquo;), as described in these Terms of Service. </p>
  </li>
  <li dir="ltr">
    <p dir="ltr" role="presentation">Should you upload Songs to the Fando Platform and allow Buyers to purchase End User Licenses (all as explained herein and in the End User Agreement) for said Songs, you also agree to the terms set forth in the End User Agreement.</p>
  </li>
  <li dir="ltr">
    <p dir="ltr" role="presentation">Users of the Fando Platform (&ldquo;Users&rdquo;) may browse the Fando Platform without an account or they may register with us for an account (&ldquo;Account&rdquo;) (any User with an Account is an &ldquo;Account Holder&rdquo;). Fando has sole discretion over whether to approve or deny an Account.</p>
  </li>
  <li dir="ltr">
    <p dir="ltr" role="presentation">You must be an Account Holder to have Songs included on the Fando Platform and to buy or sell an End User License through the Fando Platform (Account Holders who purchase an End User License through the Fando Platform are &ldquo;Buyers&rdquo;).</p>
  </li>
  <li dir="ltr">
    <p dir="ltr" role="presentation">End User Licenses are entered into between the Seller and the Buyer for that End User License.  Fando is not a party to any End User License, and does not guarantee the performance of either Seller or Buyer.  Sellers and Buyers alone are responsible for fulfilling each of their respective obligations under any End User License or any other existing obligation between Buyer and Seller.  Fando is not an agent for or representative of either the Buyer or Seller.</p>
  </li>
  <li dir="ltr">
    <p dir="ltr" role="presentation">In some cases, a Song may be associated on the Fando Platform with more than one Seller, such as where the label for that Song and a distributor for that Song each include the Song on the Fando Platform.  In such cases, any Account Holder interested in potentially purchasing an End User License for the Song may select the Seller with whom the Account Holder wishes to communicate and transact.      </p>
  </li>
  <li dir="ltr">
    <p dir="ltr" role="presentation">We may make access to certain parts or features of the Fando Platform subject to certain conditions or requirements, such as meeting specific eligibility criteria.</p>
  </li>
  <li dir="ltr">
    <p dir="ltr" role="presentation">We may modify, suspend, or discontinue the Fando Platform (any part or in its entirety) without giving you prior notice.</p>
  </li>
</ol>
<br />
<ol start="2">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>OPEN AN ACCOUNT</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">To Open an Account and become an Account Holder, you must provide accurate and full details.&nbsp; It is forbidden to provide a nonexistent email address or an email address which does not belong to you, to impersonate another person or entity, or to mislead in any other way regarding your identity or your credit card details.&nbsp;&nbsp;</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Fando reserves the right to decline a registration to join Fando or to add an Account for any lawful reason, including supply and demand, cost to maintain data, or other business considerations.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">You are required to protect the confidentiality and safety of the Account details (username and password), and you will bear full and exclusive liability for all activities in your account.&nbsp; You must inform Fando immediately of any unauthorized use of your account.</p>
    </li>
  </ol>
</ol>
<br />
<ol start="3">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>USER CONTENT</strong></p>
  </li>
</ol>
<ol type="a">
  <li dir="ltr">
    <p dir="ltr" role="presentation">By creating, uploading, sharing, posting, sending or storing Songs, photographs, artwork and/or any other intellectual property including the Seller Materials (defined below) on the Site (&ldquo;Your Content&rdquo;), you grant Fando a non-exclusive, worldwide, royalty-free, license to store, reproduce, publicly display, and distribute Your Content in connection with the functionality provided by the Fando Platform consistent with the intended use of the Fando Platform, provided that Fando will not use any of Your Content that includes personal information in violation of applicable law.  You represent and warrant, that:</p>
  </li>
</ol>
<ol>
  <ol type="i">
    <li dir="ltr">
      <p dir="ltr" role="presentation">your Content on the Fando Platform will not breach any agreement you have entered into with any third party or violate any law or regulation,</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">you have sufficient rights in Your Content to grant all rights granted by you to us under these Terms of Service to Your Content, such that no additional third-party rights or approvals are necessary for Fando to exercise those rights, and</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">our exercise of the license rights granted by you to us under these Terms of Service to Your Content will not infringe, misappropriate, or violate any third party&rsquo;s copyright, trade secret, trademark, patent, moral or other proprietary or intellectual property rights, or right of privacy or publicity.</p>
    </li>
  </ol>
</ol>
<ol type="a" start="2">
  <li dir="ltr">
    <p dir="ltr" role="presentation">Fando may remove or disable access to any of Your Content the we consider to be in violation of these Terms of Service or for any other purpose Fando finds necessary including, but not limited to, inappropriate content, supply and demand, cost to maintain data, or other business considerations or lawful reasons.</p>
  </li>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="4">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>INCLUDING SONGS ON THE FANDO PLATFORM</strong></p>
  </li>
</ol>
<ol type="a">
  <li dir="ltr">
    <p dir="ltr" role="presentation">Fando reserves the right to determine what Songs are included on the Fando Platform.</p>
  </li>
  <li dir="ltr">
    <p dir="ltr" role="presentation">If you and we agree to include your Songs on the Fando Platform, you will need to submit to us certain information about your Songs (&ldquo;Music Metadata&rdquo;), audio files for your Songs (&ldquo;Audio Files&rdquo;), and your logo (&ldquo;Seller Logo&rdquo;).  We may also obtain cover art and lyrics for your Songs, either from you or from an authorized third-party provider and you may voluntarily provide other content for use in connection with the inclusion of your Songs on the Fando Platform (e.g., text, photos or videos (any such cover art, lyrics, text, or other content, &ldquo;Supplemental Content&rdquo;) (your Music Metadata, Audio Files, Seller Logo, and Supplemental Content, collectively, &ldquo;Seller Materials&rdquo;). When you submit any Seller Materials to us for a given Song, you are agreeing to the following with respect to that Song, until you or we decide to remove the Song from the Fando Platform:</p>
  </li>
  <ol>
    <ol type="i">
      <li dir="ltr">
        <p dir="ltr" role="presentation">we may include the Song on the Fando Platform, and you authorize us to use your Seller Materials for this purpose;</p>
      </li>
      <li dir="ltr">
        <p dir="ltr" role="presentation">the &ldquo;Song&rdquo; includes all instrumental versions and stems of that Song that you make available to us for licensing through the Fando Platform;</p>
      </li>
      <li dir="ltr">
        <p dir="ltr" role="presentation">you grant us, with respect to both the Song master and the Song composition, a limited, non-exclusive, worldwide license to stream (interactively and on demand) and publicly perform the Song on the Fando Platform, in one or more file formats;</p>
      </li>
      <li dir="ltr">
        <p dir="ltr" role="presentation">you grant us a limited, non-exclusive, worldwide license to copy and display on the Fando Platform the Supplemental Content for the Song;</p>
      </li>
      <li dir="ltr">
        <p dir="ltr" role="presentation">you grant us the right to use your Seller Logo on the Fando Platform and in emails and promotional materials for the Fando Platform, to identify and promote you as a Seller on the Fando Platform;</p>
      </li>
      <li dir="ltr">
        <p dir="ltr" role="presentation">you grant us, with respect to both the Song master and the Song composition, a limited, non-exclusive, worldwide license to copy your Audio Files for the Song and make these copies, in one or more file formats, available for download from the Fando Platform by, or otherwise deliver them to any Buyer of an End User License for a Song;</p>
      </li>
      <li dir="ltr">
        <p dir="ltr" role="presentation">you represent and warrant that including the Song or Your Seller Materials on the Fando Platform will not breach any agreement you have entered into with any third party or violate any law or regulation;</p>
      </li>
      <li dir="ltr">
        <p dir="ltr" role="presentation">you represent and warrant that you have sufficient rights in the Song (both the Song master and the Song composition) and Your Seller Materials to grant all rights granted by you to us under these Terms of Service for the Song and Your Seller Materials, such that no additional third-party rights or approvals are necessary for Fando to exercise those;</p>
      </li>
      <li dir="ltr">
        <p dir="ltr" role="presentation">you represent and warrant that you have sufficient rights in the Song to negotiate, grant and execute synchronization licenses for the Song (both the Song master and the Song composition), such that, when an End User License is executed by you, no additional third party rights or approvals are necessary for the Buyer to exercise the rights granted in the End User License for the Song;</p>
      </li>
    </ol>
  </ol>
  <li dir="ltr">
    <p dir="ltr" role="presentation">you represent and warrant that our exercise of the license rights granted by you to us under these Terms of Service to the Song and Your Seller Materials will not infringe, misappropriate, or violate any third party&rsquo;s copyright, trade secret, trademark, patent, moral or other proprietary or intellectual property rights, or any right of privacy or publicity; and</p>
  </li>
  <li dir="ltr">
    <p dir="ltr" role="presentation">you will respond within a reasonably prompt timeframe to inquiries and communications from Fando or through the Fando Platform regarding a potential End User License for the Song.</p>
  </li>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="5">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>REMOVING SONGS FROM THE FANDO PLATFORM</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">Upon your request, we will remove any of your Songs from the Fando Platform.  This removal will be conducted within a reasonably prompt timeframe following your request.  When a Song is removed from the Fando Platform, a User will not be able to discover the Song through the Fando Platform, except in the case where another Seller has included the Song on the Fando Platform (e.g., it&rsquo;s possible for a label, distributor or other rightsholder to include the same Song on the Fando Platform).  If another Seller has included the Song on the Fando Platform and only you request its removal, then the Song will still be discoverable on the Fando Platform in association with such other Seller. </p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">You hold Fando harmless from any liability as a result of the issuance of an End User License subsequent to Your request for removal of a Song if the removal was not completed prior to issuance due to an undue delay on the part of Fando.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">We reserve the right to remove any of your Songs from the Fando Platform at any time and for any reason or no reason. We will notify you in advance of any such removal.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Removal of a Song from the Fando Platform does not terminate the terms of an End User License already issued for said song, which shall survive said remove and will be governed by the End User Agreement and the End User License.</p>
    </li>
  </ol>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="6">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>FANDO COMMISSION &amp; PAYMENT TERMS</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">If you are using the Fando Platform as a Buyer or potential Buyer, we do not charge you for your use of the Fando Platform. </p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">If you are using the Fando Platform as a Seller, your determine the price a Buyer must pay to purchase to an End User License (a &ldquo;Sync Fee&rdquo;).</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">If you are a Seller who has included one or more Songs on the Fando Platform, you agree to pay us a commission (the &ldquo;Commission&rdquo;) of twenty-five percent (25%) of your Sync Fee for any End User License you enter into where the End User License transaction was initiated on the Fando Platform (e.g., if the Buyer discovered the Song using the Fando Platform&rsquo;s music discovery features).&nbsp;</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Your Commission is automatically charged and distributed to Fando upon the Purchase directly from Buyer to Fando via a third-party processor, PayPal as further defined in Section 12 below.&nbsp; Should the Commission not be automatically withdrawn, same is due within thirty (30) days after you enter into the End User License with the Buyer for the Song. &nbsp;</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Commissions do not include taxes.  You are responsible for paying applicable taxes on Commissions.  Depending on the laws of the relevant jurisdiction, we may charge Value Added Tax (&ldquo;VAT&rdquo;) on top of the Commission.  We may deliver invoices and payment reminders to the email address on file with your Account. If we are unable to collect a Commission from you due to insufficient funds, you will bear the costs we may incur as a result (including but not limited to bank charges).</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">We may change our Commission pricing from time to time.  In such event, if you are a Seller we will provide you with advance notice of such change on the Fando Platform website and, if you have an Account, by sending an email to the email address associated with your Account.</p>
    </li>
  </ol>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="7">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>THIRD PARTY RIGHTSHOLDERS</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">Upon uploading any Song to the Fando Platform, you agree to provide the email address(es) of any third party(ies) entitled to a percentage share of the Sync Fee (&ldquo;Third Party Rightsholder&rdquo;) and the percentage share to be paid to the Third Party Rightsholder. Any Song uploaded to the Fando Platform that contains a Third Party Rightsholder is defined as a &ldquo;Jointly Owned Song.&rdquo;</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">You understand and agree to allow Fando to notify via email the Third Party Rightsholder of your intent to upload the Jointly Owned Song to the Fando Platform.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">You understand Fando will not permit the Jointly Owned Song be available publicly on the Fando Platform for sale until (1) all Third Party Rightsholders have consented to uploading the Jointly Owned Song to the Fando Platform, (2) all Third Party Rightsholders have consented to the terms of this Agreement and (3) all Third Party Rightsholders have provided PayPal accounts for payment of their pro rata share of the Sync Fee.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Upon the completion of subsection (c) above, all Third Party Rightsholders will be paid their pro rata share of their Sync Fee (less the Commission) in accordance with the terms of this Agreement and the End User Agreement.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Failure to properly provide Third Party Rightsholders shall place you in breach of the terms of this Agreement, and you agree to fully indemnify Fando as set forth in Section 15 below.</p>
    </li>
  </ol>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="8">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>OWNERSHIP OF THE FANDO PLATFORM AND FANDO CONTENT</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">All right, title and interest in the Fando Platform and the Fando Content, including the intellectual property therein, is owned by Fando and its licensors. We reserve on behalf of ourselves and our licensors, all rights in and to the Fando Platform and the Fando Content that are not expressly granted under these Terms of Service. No additional rights are granted, whether by implication, estoppel or otherwise.  When we use Seller trademarks or logos on the Fando Platform, we do so to identify those Sellers, and we do not claim ownership of those trademarks or logos.</p>
    </li>
  </ol>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="9">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>FEEDBACK</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">We welcome your suggestions, comments and other input to us on how we might improve the Fando Platform (collectively, &ldquo;Feedback&rdquo;).  If you provide us with Feedback, you agree that we may use and exploit your Feedback in any way we see fit, without obligation of any kind to you.</p>
    </li>
  </ol>
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>COPYRIGHT POLICY</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">We respect the intellectual property of others and asks that Users of the Fando Platform do the same.  In connection with the Fando Platform, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination, in appropriate circumstances, of Users who are repeat infringers of intellectual property rights, including copyrights.  If you believe that one of our Users is, through the use of the Fando Platform, unlawfully infringing the copyright(s) in a Work, Seller Materials or any other intellectual property uploaded on the Fando Platform, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated Copyright Agent:</p>
    </li>
  </ol>
</ol>
<ol>
  <ol>
    <ol>
      <ol>
        <ol type="i">
          <li dir="ltr">
            <p dir="ltr" role="presentation">your physical or electronic signature;</p>
          </li>
          <li dir="ltr">
            <p dir="ltr" role="presentation">identification of the copyrighted work(s) that you claim to have been infringed;</p>
          </li>
          <li dir="ltr">
            <p dir="ltr" role="presentation">identification of the material on the Fando Platform that you claim is infringing and that you request us to remove;</p>
          </li>
          <li dir="ltr">
            <p dir="ltr" role="presentation">sufficient information to permit us to locate such material;</p>
          </li>
          <li dir="ltr">
            <p dir="ltr" role="presentation">your address, telephone number, and e-mail address;</p>
          </li>
          <li dir="ltr">
            <p dir="ltr" role="presentation">a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and</p>
          </li>
          <li dir="ltr">
            <p dir="ltr" role="presentation">a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.</p>
          </li>
        </ol>
      </ol>
    </ol>
  </ol>
</ol>
<p dir="ltr">Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact (falsities) in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney&rsquo;s fees incurred by us in connection with the written notification and allegation of copyright infringement.</p>
<p dir="ltr">Our agent for notice of claims of copyright infringement for the Fando Platform can be reached by mail and email at:&nbsp;</p>
<p dir="ltr">&nbsp;</p>
<p dir="ltr">David Whitfield Morris</p>
<p dir="ltr">1720 Beachwood Ave.</p>
<p dir="ltr">Nashville, TN 37212</p>
<p dir="ltr"> </p>
<ol start="11">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>THIRD-PARTY WEBSITES</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">The Fando Platform may include links to third-party websites. We make no representations or warranties, either express or implied, regarding any third-party website.</p>
    </li>
  </ol>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="12">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>PAYMENT FOR END USER LICENSE</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">Seller has full authority to determine the price of each Work available for purchase of an End User License.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">The purchase of an End User License (the &ldquo;Purchase&rdquo;) is transacted through the services of PayPal Holdings, Inc. or its affiliated entities (&ldquo;PayPal&rdquo;).&nbsp; Both the Buyer and the Seller must abide by any requirements of PayPal to complete the Purchase.&nbsp; Failure to do so does not deem Fando liable in any manner.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Upon Purchase, Fando shall receive its Commission via PayPal directly.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Fando does not, in any manner, handle finances of the Buyer or Seller, nor does Fando have any supervision, direction or handling of the operations and/or of the Purchase.&nbsp;&nbsp;</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">You understand, acknowledge and agree that Fando is in no manner liable for an incomplete Purchase due to the fault of PayPal.</p>
    </li>
  </ol>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="13">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>LIMITATION OF FANDO’S LIABILITY</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">The Works and the Site&rsquo;s Services provided in the Site are provided for use &ldquo;As Is&rdquo; and &ldquo;As Available&rdquo; and you will bear no argument, claim or demand toward Fando for the suitability for your needs.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Fando does not warrant the Site&rsquo;s services will not be interrupted or will be immune to damage, malfunctions, defects or failures and all hardware, software, communication systems and lines, at the Site or at any of its suppliers.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Fando will bear no liability in case any of the Works are not available in the Site, for any reason whatsoever.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Fando will bear no liability for any direct or indirect damage, pecuniary or another, which you might incur consequent upon (1) changes the Site may make to its Services, (2) ceasing temporarily or permanently, the provision of the Services, (3) changing, adding or removing any of the Services&rsquo; characteristics or properties, (4) changing adding or removing any contents from the Site and/or Works from the Catalog, all of any of them.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Fando does not make any representations about or guarantee the quality or legality of the Works or the services of PayPal.&nbsp; You acknowledge, agree, and understand that Fando does not, in any way, supervise, direct, control, or evaluate Content Creators or their Works.&nbsp;&nbsp;</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Fando makes no representations about and does not guarantee, and you agree not to hold Fando responsible for any issues with the Works including, but not limited to, the quality, or legality of the Works, including whether the Works infringe a third party&rsquo;s intellectual property rights.</p>
    </li>
  </ol>
</ol>
<br />
<ol start="14">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>RELEASE</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">You hereby release Fando, our affiliated entities, and our respective officers, members, directors, agents, subsidiaries, joint ventures, employees and service providers from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with any dispute you have with another User, Account Holder, Buyer and/or Seller, whether it be at law or in equity that exist as of the time you enter into this Agreement. This release includes, for example and without limitation, any disputes regarding the performance, functions, and quality of the Works purchased and requests for refunds based upon disputes.&nbsp;</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">Without limiting the generality of the foregoing, this section includes your release of Fando&rsquo;s liability from any third-party claims against you relating to allegations that Buyer or Seller violated the End User License or otherwise infringed any applicable intellectual property laws.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">This release will not apply to a claim that Fando failed to meet our obligations under the Terms of Service.</p>
    </li>
  </ol>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="15">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>INDEMNIFICATION</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">You will indemnify, defend, and hold harmless Fando, our affiliated entities, and our respective directors, officers, members, employees, representatives, and agents (each an &ldquo;Indemnified Party&rdquo;) for all Indemnified Claims (defined below) and Indemnified Liabilities (defined below) relating to or arising out of: (a) the use of the Site and the Site Services by you or your agents, including any payment obligations or default incurred through use of the Site Services; (b) any Works purchased improperly; (c) failure to comply with the Terms of Service by you or your agents; (d) failure to comply with applicable law by you or your agents; (e) failure to abide by the applicable End User License; and (f) defamation, libel, violation of privacy rights, unfair competition, or infringement of Intellectual Property Rights or allegations thereof to the extent caused by you or your agents. For purposes of this section, your agents include any person who has apparent authority to access or use your account demonstrated by using your username and password.</p>
    </li>
    <ol type="i">
      <li dir="ltr">
        <p dir="ltr" role="presentation">&ldquo;Indemnified Claim&rdquo; means any and all claims, damages, liabilities, costs, losses, and expenses (including reasonable attorneys&rsquo; fees and all related costs and expenses) arising from or relating to any claim, suit, proceeding, demand, or action brought by you, another User, Account Holder, Buyer, Seller, a third party or other End User against an Indemnified Party.</p>
      </li>
      <li dir="ltr">
        <p dir="ltr" role="presentation">&ldquo;Indemnified Liability&rdquo; means any and all claims, damages, liabilities, costs, losses, and expenses (including reasonable attorneys&rsquo; fees and all related costs and expenses) arising from or relating to any claim, suit, proceeding, demand, or action brought by an Indemnified Party against you or a third party or other User.</p>
      </li>
    </ol>
  </ol>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="16">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>TERMINATION</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">Unless both you and Fando expressly agree otherwise in writing, either of us may terminate this Agreement in our sole discretion, at any time, without explanation, upon written notice to the other, which will result in the termination of the other Terms of Service as well, except as otherwise provided herein. In the event you properly terminate this Agreement, your right to use the Site and Site Services is automatically revoked, and your Account will be closed. Consequently, User understands and acknowledges that termination of this Agreement (or attempt to terminate this Agreement) does not terminate or otherwise impact any End User License Agreement, which shall carry on in perpetuity in relation to the Purchase of an End User License of a Licensed Work.&nbsp;</p>
    </li>
  </ol>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="17">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>SURVIVAL</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">After this Agreement terminates, the terms of this Agreement and the other Terms of Service that expressly or by their nature contemplate performance after this Agreement terminates or expires will survive and continue in full force and effect. For example, the provisions requiring protecting intellectual property, setting forth limitations of liability and the End User License each, by their nature, contemplate performance or observance after this Agreement terminates. Without limiting any other provisions of the Terms of Service, the termination of this Agreement for any reason will not release you or Fando from any obligations incurred prior to termination of this Agreement or that thereafter may accrue in respect of any act or omission prior to such termination.</p>
    </li>
  </ol>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="18">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>ENTIRE AGREEMENT, MODIFICATION AND WAIVER</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">This Agreement, together with the End User Agreement, sets forth the entire agreement and understanding between you and Fando relating to the subject matter hereof and thereof and cancels and supersedes any prior or contemporaneous discussions, agreements, representations, warranties, and other communications between you and us, written or oral, to the extent they relate in any way to the subject matter hereof and thereof. The section headings in the Terms of Service are included for ease of reference only and have no binding effect. Even though Fando drafted the Terms of Service, you represent that you had ample time to review and decide whether to agree to the Terms of Service. If an ambiguity or question of intent or interpretation of the Terms of Service arises, no presumption or burden of proof will arise favoring or disfavoring you or Fando because of the authorship of any provision of the Terms of Service.</p>
    </li>
    <li dir="ltr">
      <p dir="ltr" role="presentation">No modification or amendment to the Terms of Service will be binding upon Fando unless in a written instrument signed by a duly authorized representative of Fando or posted on the Site by Fando. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. We do not guarantee we will take action against all breaches of this Agreement.</p>
    </li>
  </ol>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="19">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>ASSIGNABILITY</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">User may not assign the Terms of Service, or any of its rights or obligations hereunder, without Fando&rsquo;s prior written consent in the form of a written instrument signed by a duly authorized representative of Fando. Fando may freely assign this Agreement without User&rsquo;s consent. Any attempted assignment or transfer in violation of this subsection will be null and void. Subject to the foregoing restrictions, the Terms of Service are binding upon and will inure to the benefit of the successors, heirs, and permitted assigns of the parties.</p>
    </li>
  </ol>
</ol>
<p dir="ltr">&nbsp;</p>
<ol start="20">
  <li dir="ltr">
    <p dir="ltr" role="presentation"><strong>SEVERABILITY</strong></p>
  </li>
  <ol type="a">
    <li dir="ltr">
      <p dir="ltr" role="presentation">If and to the extent any provision of this Agreement is held illegal, invalid, or unenforceable in whole or in part under applicable law, such provision or such portion thereof will be ineffective as to the jurisdiction in which it is illegal, invalid, or unenforceable to the extent of its illegality, invalidity, or unenforceability and will be deemed modified to the extent necessary to conform to applicable law so as to give the maximum effect to the intent of the parties. The illegality, invalidity, or unenforceability of such provision in that jurisdiction will not in any way affect the legality, validity, or enforceability of such provision in any other jurisdiction or of any other provision in any jurisdiction.</p>
    </li>
  </ol>
</ol>
  </>
}